import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
export var LoginSSO = function (props) {
    var hasExipered = function (token) {
        try {
            var payload = JSON.parse(atob(token.split(".")[1]));
            if (payload.exp * 1000 > Date.now()) {
                return false;
            }
            return true;
        }
        catch (_) {
            return true;
        }
    };
    useEffect(function () {
        var _a;
        var query = new URLSearchParams(window.location.search);
        var token = query.get("token") || localStorage.getItem("token");
        var patientToken = query.get("patientToken") || localStorage.getItem("patientToken");
        var callback = window.location.href.split("?")[0];
        var meta = {};
        if (query.get("redirect")) {
            meta.redirect = query.get("redirect");
        }
        if (query.get("plano")) {
            meta.plano = query.get("plano");
        }
        if (query.get("valorPlano")) {
            meta.valorPlano = query.get("valorPlano");
        }
        if (!(token && patientToken) || hasExipered(token)) {
            var params_1 = new URLSearchParams({
                logout: "1",
                callback: callback,
                appOrigin: props.login.clientId || query.get("clientId"),
                meta: JSON.stringify(meta),
            });
            window.location =
                "".concat(process.env.MICROAPP_LOGIN_URL, "?").concat(params_1);
            return;
        }
        var url = atob(((_a = props === null || props === void 0 ? void 0 : props.login) === null || _a === void 0 ? void 0 : _a.defaultRedirect) || query.get("redirect"));
        if (query.get("redirect")) {
            url = atob(query.get("redirect"));
        }
        var params = new URLSearchParams();
        if (query.get("tpUser")) {
            params.set("tpUser", query.get("tpUser"));
        }
        if (query.get("meta")) {
            var metadata = JSON.parse(query.get("meta"));
            params.set("meta", query.get("meta"));
            if (metadata.redirect) {
                url = atob(metadata.redirect);
            }
        }
        localStorage.setItem("api_token", token);
        localStorage.setItem("api_patientToken", patientToken);
        localStorage.setItem("token", token);
        localStorage.setItem("patientToken", patientToken);
        window.location.replace("".concat(url, "?").concat(params));
    }, []);
    return _jsx(_Fragment, {});
};
