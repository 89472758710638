export function useLocalStorage() {
    var localStorage = window.localStorage;
    var getItem = function (key) {
        try {
            var raw = localStorage.getItem(key);
            return JSON.parse(raw);
        }
        catch (error) {
            return localStorage.getItem(key);
        }
    };
    var setItem = function (key, data) {
        if (typeof data === "string") {
            localStorage.setItem(key, data);
            return localStorage;
        }
    };
    return { localStorage: localStorage, setItem: setItem, getItem: getItem };
}
