var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { put, takeLatest } from "redux-saga/effects";
import { MFA_PAGE, MFA_STATUS } from "../constants";
import { MFAAuth, MFAToast, postMFAGenerate, postMFAVerificate, setMFAGenerateResponse, setMFAOpenModal, setMFAPage, setMFAStatus, setMFAVerificateResponse, setMFAWait, } from "./actions";
// const [, setToast] = useToast();
function mfaToastSaga(prop) {
    var payload;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = (prop || {}).payload;
                // setToast({
                //   show: true,
                //   type: "error",
                //   message: typeof payload === "string" ? payload : JSON.stringify(payload),
                // });
                return [4 /*yield*/, put(setMFAWait(false))];
            case 1:
                // setToast({
                //   show: true,
                //   type: "error",
                //   message: typeof payload === "string" ? payload : JSON.stringify(payload),
                // });
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function mfaGeneratorPendingSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(setMFAWait(true))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function mfaVerificatePendingSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(setMFAWait(true))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function mfaAuthSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(setMFAOpenModal(true))];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(setMFAPage(MFA_PAGE.SELECT_COMMUNICATION_WAY))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(setMFAStatus(MFA_STATUS.INITIAL))];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function mfaGeneratorFulfilledSaga(action) {
    var _a, key, expiresAt, expiresInMinutes, expiresInSecconds, digitsLenght, email, sms;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload || {}, key = _a.key, expiresAt = _a.expiresAt, expiresInMinutes = _a.expiresInMinutes, expiresInSecconds = _a.expiresInSecconds, digitsLenght = _a.digitsLenght, email = _a.email, sms = _a.sms;
                if (!key) return [3 /*break*/, 2];
                return [4 /*yield*/, put(setMFAGenerateResponse({
                        key: key,
                        expiresAt: expiresAt,
                        expiresInMinutes: expiresInMinutes,
                        expiresInSecconds: expiresInSecconds,
                        digitsLenght: digitsLenght,
                        email: email,
                        sms: sms,
                    }))];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2: return [4 /*yield*/, put(setMFAWait(false))];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function mfaVerificateFulfilledSaga(action) {
    var _a, error, message, statusCode;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload || {}, error = _a.error, message = _a.message, statusCode = _a.statusCode;
                return [4 /*yield*/, put(setMFAVerificateResponse({
                        error: error,
                        message: message,
                        statusCode: statusCode,
                    }))];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(setMFAStatus(MFA_STATUS.ALLOW))];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function mfaGeneratorRejectedSaga(prop) {
    var error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                error = ((prop === null || prop === void 0 ? void 0 : prop.payload) || {}).error;
                // setToast({
                //   show: true,
                //   type: "error",
                //   message: error,
                // });
                return [4 /*yield*/, put(setMFAWait(false))];
            case 1:
                // setToast({
                //   show: true,
                //   type: "error",
                //   message: error,
                // });
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function mfaVerificateRejectedSaga(payload) {
    var _a, error, message, statusCode;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = payload || {}, error = _a.error, message = _a.message, statusCode = _a.statusCode;
                return [4 /*yield*/, put(setMFAVerificateResponse({
                        error: error,
                        message: message,
                        statusCode: statusCode,
                    }))];
            case 1:
                _b.sent();
                if (!(String(message).indexOf("incorreto") > -1)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setMFAStatus(MFA_STATUS.WRONG_CODE))];
            case 2:
                _b.sent();
                return [3 /*break*/, 9];
            case 3:
                if (!(String(message).indexOf("expirado") > -1)) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setMFAStatus(MFA_STATUS.TIME_EXPIRED))];
            case 4:
                _b.sent();
                return [3 /*break*/, 9];
            case 5:
                if (!(String(message).indexOf("solicite") > -1)) return [3 /*break*/, 7];
                return [4 /*yield*/, put(setMFAStatus(MFA_STATUS.NEED_A_NEW_CODE))];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, put(setMFAStatus(MFA_STATUS.UNKNOWN_ERROR))];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [4 /*yield*/, put(setMFAWait(false))];
            case 10:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
var mfaSaga = [
    takeLatest(MFAAuth.match, mfaAuthSaga),
    takeLatest(MFAToast.match, mfaToastSaga),
    takeLatest(postMFAGenerate.pending.match, mfaGeneratorPendingSaga),
    takeLatest(postMFAGenerate.fulfilled.match, mfaGeneratorFulfilledSaga),
    takeLatest(postMFAGenerate.rejected.match, mfaGeneratorRejectedSaga),
    takeLatest(postMFAVerificate.pending.match, mfaVerificatePendingSaga),
    takeLatest(postMFAVerificate.fulfilled.match, mfaVerificateFulfilledSaga),
    takeLatest(postMFAVerificate.rejected.match, mfaVerificateRejectedSaga),
];
export default mfaSaga;
