var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var ToastStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  position: fixed;\n  justify-content: center;\n  align-items: center;\n  /* opacity: 0; */\n  width: 100%;\n  top: 10%;\n  left: 0px;\n  z-index: 1000;\n  div {\n    background-color: ", ";\n    color: #fff;\n    padding: 20px;\n    border-radius: 4px;\n    opacity: 0.6;\n    margin-top: 10px;\n  }\n"], ["\n  display: flex;\n  position: fixed;\n  justify-content: center;\n  align-items: center;\n  /* opacity: 0; */\n  width: 100%;\n  top: 10%;\n  left: 0px;\n  z-index: 1000;\n  div {\n    background-color: ", ";\n    color: #fff;\n    padding: 20px;\n    border-radius: 4px;\n    opacity: 0.6;\n    margin-top: 10px;\n  }\n"])), function (props) {
    if (props.variation === "error")
        return "red";
    if (props.variation === "success")
        return "green";
    return "#000";
});
var templateObject_1;
