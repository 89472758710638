export var errorException;
(function (errorException) {
    errorException["AZ_B2C_LOGIN_UNAUTHORIZED"] = "EAX1001";
    errorException["COGNITO_LOGIN_UNAUTHORIZED"] = "ECX1001";
    errorException["COGNITO_LOGIN_PASSWORD_ATTEMPTS_EXCEEDED"] = "ECX1006";
    errorException["COGNITO_LOGIN_DISABLED_USER"] = "ECX1005";
    errorException["COGNITO_LOGIN_RESET_REQUIRED"] = "ECX1003";
    errorException["COGNITO_ACCOUNT_UNCONFIRMED"] = "ECX1002";
    errorException["JWT_SIGN_ERROR"] = "EXX1001";
    errorException["JWT_INVALID_PAYLOAD"] = "EXX1002";
    errorException["ME_DB_MISSING_USER"] = "EMX1001";
    errorException["ME_DB_SEARCH_UNKNOWN_ERROR"] = "EMX1002";
    errorException["ME_CREATE_ERROR"] = "EMX1003";
    errorException["DB_MISSING_USER"] = "EDX1001";
    errorException["DB_CRATE_ERR"] = "EDX1002";
    errorException["COGNITO_LOGIN_UNKNOW_ERR"] = "ECX1004";
    errorException["COGNITO_SIGN_UP_ERR"] = "ECX1011";
    errorException["COGNITO_ACCOUNT_CONFIRM_ERR"] = "ECX1012";
    errorException["COGNITO_EMAIL_CONFIRM_ERR"] = "ECX1013";
    errorException["COGNITO_SET_PASSWORD_ERROR"] = "ECX1014";
    errorException["AZ_B2C_LOGIN_UNKNOWN_ERR"] = "EAX1002";
    errorException["AZ_B2C_LOGIN_UNKNOWN_ERR_CODE"] = "EAX1003";
})(errorException || (errorException = {}));
export var errorMessage = {
    GatewayTimeout: "Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde.",
    UserNotFoundException: "Não foi possível enviar o e-mail, usuário não encontrado.",
    NotAuthorizedException: "Usuário ou senha incorretos.",
    PasswordResetRequiredException: "Não foi possível realizar login, redefina sua senha.",
    UserNotConfirmedException: "Não foi possível realizar login, usuário não confirmado.",
    ResourceUserException: "Não foi possível realizar login, dados não encontrados.",
    RequalificationRequired: "Não foi possível realizar o login, realize a requalificação da sua conta.",
    default: "Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde.",
};
