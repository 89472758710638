export var ErrorsTypes = {
    InvalidDocumentException: "InvalidDocumentException",
    UserActionClientDisabledException: "UserActionClientDisabledException",
    UserActionClientKeysMisconfiguredException: "UserActionClientKeysMisconfiguredException",
    UserActionClientMisconfiguredException: "UserActionClientMisconfiguredException",
    UserActionNotEligibleException: "UserActionNotEligibleException",
    UserActionClientNotFoundException: "UserActionClientNotFoundException",
    UserActionInconsistentUserException: "UserActionInconsistentUserException",
    ServiceUnavailableException: "ServiceUnavailableException",
    InternalServerError: "InternalServerError",
};
