var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ModalBase, Button } from "../../../components";
import MailIcon from "../../../assets/icons/mail.svg";
export var RegisterSuccessModal = function (props) {
    var isOpen = props.isOpen;
    var goToLogin = function () {
        return (window.location.href = "/login".concat(window.location.search));
    };
    return (_jsx(ModalBase, { isOpen: isOpen, content: _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "title" }, { children: _jsx("h2", { children: "Conta criada com sucesso." }) })), _jsx("div", __assign({ className: "icon" }, { children: _jsx("img", { src: MailIcon, width: "179", height: "92", alt: "e-mail" }) })), _jsx("p", { children: "Por favor acesse o e-mail para realizar a confirma\u00E7\u00E3o." })] }), bottomContent: _jsx(_Fragment, { children: _jsx(Button, __assign({ onClick: goToLogin }, { children: "Ir para o Login" })) }) }));
};
