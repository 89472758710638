var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Container } from "../Container";
export var HeaderSite = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  background-color: white;\n  z-index: 10;\n  top: 0;\n  left: 0;\n"], ["\n  position: fixed;\n  width: 100%;\n  background-color: white;\n  z-index: 10;\n  top: 0;\n  left: 0;\n"])));
export var HeaderContainer = styled(Container)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 12px;\n  padding-bottom: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 63px;\n  .label {\n    font-weight: 600;\n    color: #004f92;\n    font-size: 17px;\n    border-left: solid 1px #004f9269;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 1rem;\n    margin-left: 1rem;\n  }\n  svg {\n    height: 23px;\n  }\n\n  .logo-header {\n    max-height: 35px;\n  }\n\n  @media (min-width: 768px) {\n    justify-content: flex-start;\n    width: 100%;\n  }\n"], ["\n  padding-top: 12px;\n  padding-bottom: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 63px;\n  .label {\n    font-weight: 600;\n    color: #004f92;\n    font-size: 17px;\n    border-left: solid 1px #004f9269;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 1rem;\n    margin-left: 1rem;\n  }\n  svg {\n    height: 23px;\n  }\n\n  .logo-header {\n    max-height: 35px;\n  }\n\n  @media (min-width: 768px) {\n    justify-content: flex-start;\n    width: 100%;\n  }\n"])));
export var GradientLine = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-image: linear-gradient(\n    to right,\n    #ecb561 0%,\n    #de656b 30%,\n    #4595c4 60%,\n    #3ad5ca 100%\n  );\n  height: 8px;\n"], ["\n  background-image: linear-gradient(\n    to right,\n    #ecb561 0%,\n    #de656b 30%,\n    #4595c4 60%,\n    #3ad5ca 100%\n  );\n  height: 8px;\n"])));
export var ImgLogo = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-height: 170px;\n  cursor: pointer;\n"], ["\n  max-height: 170px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
