var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Yup from "yup";
import React, { useState } from "react";
import { Banner, Button, Input, PasswordCheck, Header, } from "../../../../components";
import { Content } from "../../../../assets/styles/GlobalStyles";
import { FormSection, Wrapper } from "../../../Login/styles";
import { useToast } from "../../../../hooks/toast";
import { recoverPasswordRequest } from "../../redux/actions";
import { useDispatch } from "../../../../redux/root/store";
import { setMFAStatus } from "../../../MFA/redux/actions";
import { MFA_STATUS } from "../../constants";
import { useFormik } from "formik";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmField, password, } from "../../../../validation/validators/formSchema";
var ResetPassword = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useToast(), setToast = _b[1];
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var params = location.state;
    var goBack = function () { return navigate("/login"); };
    var formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: Yup.object({
            password: password,
            confirmPassword: confirmField("password", "Os valores dos campos senha e confirmação não são iguais"),
        }),
        onSubmit: function (values) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var _d, message, data, status, ex_1, message;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            if (values.password !== values.confirmPassword) {
                                setToast({
                                    show: true,
                                    type: "error",
                                    message: "As senhas não conferem",
                                });
                                return [2 /*return*/];
                            }
                            setLoading(true);
                            _e.label = 1;
                        case 1:
                            _e.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, dispatch(recoverPasswordRequest({
                                    key: params.key,
                                    code: params.code,
                                    password: values.password,
                                })
                                // @ts-ignore
                                ).then(unwrapResult)];
                        case 2:
                            _d = _e.sent(), message = _d.message, data = _d.data, status = _d.status;
                            if (status === 202) {
                                setToast({
                                    show: true,
                                    type: "success",
                                    message: "Senha alterada com sucesso",
                                });
                                setTimeout(function () {
                                    goBack();
                                }, 2000);
                            }
                            else if (data.type === "MFA_INVALID") {
                                dispatch(setMFAStatus(MFA_STATUS.WRONG_CODE));
                                navigate(-2);
                            }
                            else if (message)
                                setToast({
                                    show: true,
                                    type: "error",
                                    message: message,
                                });
                            return [3 /*break*/, 4];
                        case 3:
                            ex_1 = _e.sent();
                            message = (_c = (_b = (_a = ex_1 === null || ex_1 === void 0 ? void 0 : ex_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : ex_1.message;
                            setToast({
                                show: true,
                                type: "error",
                                message: message,
                            });
                            return [3 /*break*/, 4];
                        case 4:
                            setLoading(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    });
    var getError = function (key) {
        return formik.errors[key] && formik.touched[key] && formik.errors[key];
    };
    var getErrorFeedback = function (key) { return ({
        show: !!getError(key),
        text: getError(key),
        variation: "error",
    }); };
    return (_jsx(Content, { children: _jsxs(Wrapper, { children: [_jsx(Header, {}), _jsxs(FormSection, { children: [_jsx("h4", __assign({ style: { marginBottom: 20 } }, { children: "Defina sua nova senha" })), _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(Input, { placeholder: "******", width: "100%", name: "password", label: "Nova Senha", type: "password", value: formik.values.password, feedback: getErrorFeedback("password"), onChange: function (e) {
                                        formik.setFieldValue("password", e.target.value);
                                    }, viewValue: true }), _jsx(Input, { placeholder: "******", width: "100%", name: "confirmPassword", label: "Confirme sua senha", type: "password", value: formik.values.confirmPassword, feedback: getErrorFeedback("confirmPassword"), onChange: function (e) {
                                        formik.setFieldValue("confirmPassword", e.target.value);
                                    }, viewValue: true }), _jsx(PasswordCheck, { password: formik.values.password, confirmPassword: formik.values.confirmPassword }), _jsx(Button, __assign({ loading: loading, type: "submit" }, { children: "Alterar" })), _jsx(Button, __assign({ variation: "outline", type: "button", onClick: goBack }, { children: "Voltar ao login" }))] }))] }), _jsx(Banner, {})] }) }));
};
export default ResetPassword;
