var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Container } from "./styles";
export var InputFeedback = function (_a) {
    var variation = _a.variation, children = _a.children;
    if (variation === "success") {
        return (_jsx(Container, __assign({ color: "#4caf50" }, { children: _jsx("span", { children: children }) })));
    }
    if (variation === "error") {
        return (_jsx(Container, __assign({ color: "#ff6347" }, { children: _jsx("span", { children: children }) })));
    }
    return (_jsx(Container, __assign({ color: "#222" }, { children: _jsx("span", { children: children }) })));
};
