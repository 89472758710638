import * as Yup from "yup";
import { birthDate, firstName, lastName, phone, sex, cpf, foreingPassaport, nationality, email, confirmField, password, termService, termProcess, foreignPhone, } from "../../../validation/validators/formSchema";
var isBrazilian = function (nationalityParam) {
    return nationalityParam && nationalityParam.toLowerCase() === "brasileiro";
};
export var validationRegisterSchema = Yup.object({
    firstName: firstName,
    lastName: lastName,
    birthDate: birthDate,
    sex: sex,
    nationality: nationality,
    document: Yup.string().when(["nationality"], {
        is: isBrazilian,
        then: cpf,
        otherwise: foreingPassaport,
    }),
    phone: Yup.string().when(["nationality"], {
        is: isBrazilian,
        then: phone,
        otherwise: foreignPhone,
    }),
    email: email,
    confirmEmail: confirmField("email", "Os valores dos campos e-mail e confirmação, não são iguais"),
    password: password,
    confirmPassword: confirmField("password", "Os valores dos campos senha e confirmação não são iguais"),
    termProcess: termService,
    termService: termProcess,
});
