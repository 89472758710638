import { createSelector } from "@reduxjs/toolkit";
export var getMFASlice = function (state) { return state === null || state === void 0 ? void 0 : state.mfa; };
export var getMFAWait = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.wait; });
export var getMFAStatus = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.status; });
export var getMFATime = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.status; });
export var getMFADigitsLength = createSelector([getMFASlice], function (slice) { var _a; return ((_a = slice === null || slice === void 0 ? void 0 : slice.generate) === null || _a === void 0 ? void 0 : _a.response.digitsLenght) || 6; });
export var getMFAPage = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.page; });
export var getBackToRouteName = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.backToRouteName; });
export var getUrlVerificationCode = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.urlVerificationCode; });
export var getMFAGenerateSelectedWay = createSelector([getMFASlice], function (slice) { var _a, _b; return (_b = (_a = slice === null || slice === void 0 ? void 0 : slice.generate) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.selected; });
export var getMFAGenerateReason = createSelector([getMFASlice], function (slice) { var _a, _b; return (_b = (_a = slice === null || slice === void 0 ? void 0 : slice.generate) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.reason; });
export var getMFAGenerateResponse = createSelector([getMFASlice], function (slice) { var _a; return (_a = slice === null || slice === void 0 ? void 0 : slice.generate) === null || _a === void 0 ? void 0 : _a.response; });
export var getMFAGenerateRequest = createSelector([getMFASlice], function (slice) { var _a; return (_a = slice === null || slice === void 0 ? void 0 : slice.generate) === null || _a === void 0 ? void 0 : _a.request; });
export var getMFAVerificateRequest = createSelector([getMFASlice], function (slice) { var _a; return (_a = slice === null || slice === void 0 ? void 0 : slice.verificate) === null || _a === void 0 ? void 0 : _a.request; });
export var getMFAVerificateKey = createSelector([getMFASlice], function (slice) { var _a, _b; return (_b = (_a = slice === null || slice === void 0 ? void 0 : slice.generate) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.key; });
export var getMFAVerificateCode = createSelector([getMFASlice], function (slice) { var _a, _b; return (_b = (_a = slice === null || slice === void 0 ? void 0 : slice.verificate) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.code; });
export var getMFAVerificateResponse = createSelector([getMFASlice], function (slice) { var _a; return (_a = slice === null || slice === void 0 ? void 0 : slice.verificate) === null || _a === void 0 ? void 0 : _a.response; });
export var getMFAShowOptions = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.showOptions; });
export var getMFAOpenModal = createSelector([getMFASlice], function (slice) { return slice === null || slice === void 0 ? void 0 : slice.openModal; });
