var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from "react-modal";
import React, { useState } from "react";
import SvgCloseIcon from "../../assets/icons/close.svg";
import { ButtonClose, ButtonCloseContainer, ModalBottom, ModalContent, ModalTop, } from "./styles";
var customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "60px",
        width: "auto",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        display: "flex",
        justifyContent: "center",
    },
};
export var ModalBase = function (props) {
    var isOpen = props.isOpen, _a = props.isCloseable, isCloseable = _a === void 0 ? false : _a, content = props.content, bottomContent = props.bottomContent, onRequestClose = props.onRequestClose;
    var _b = useState(false), isClosed = _b[0], setClosed = _b[1];
    var closeButton = null;
    if (isCloseable) {
        closeButton = (_jsx(ButtonCloseContainer, { children: _jsx(ButtonClose, __assign({ type: "button", onClick: function () { return setClosed(true); } }, { children: _jsx("img", { src: SvgCloseIcon, width: "24", height: "24", alt: "close" }) })) }));
    }
    return (_jsxs(Modal, __assign({ onRequestClose: onRequestClose, isOpen: isOpen && !isClosed, style: customStyles }, { children: [closeButton, _jsxs(ModalContent, { children: [_jsx(ModalTop, { children: content }), _jsx(ModalBottom, { children: bottomContent })] })] })));
};
