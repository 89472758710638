var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { patient_api, mc_api } from "../../../infra/http/axios-http-client";
var prefix = "recover";
export var updatePasswordRequest = createAsyncThunk("".concat(prefix, "/updatePasswordRequest"), function (passwords, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var newPassword, oldPassword, response, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    newPassword = passwords.newPassword, oldPassword = passwords.oldPassword;
                    return [4 /*yield*/, patient_api.put("users/change-password", {
                            newPassword: newPassword,
                            oldPassword: oldPassword,
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, "success"];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, rejectWithValue("Não foi possivel realizar a troca de senha")];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var recoverPasswordGetOptionsRequest = createAsyncThunk("".concat(prefix, "/recoverPasswordGetOptionsRequest"), function (_a) {
    var document = _a.document;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, ex_1;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, mc_api.post("/mc/reset-password/document", {
                            document: document,
                        })];
                case 1:
                    response = _e.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    ex_1 = _e.sent();
                    return [2 /*return*/, { message: (_d = (_c = (_b = ex_1 === null || ex_1 === void 0 ? void 0 : ex_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : ex_1.message }];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var setRecoverPasswordOptions = createAction("".concat(prefix, "/setRecoverPasswordOptions"));
export var verifyRecoverPasswordCodeRequest = createAsyncThunk("".concat(prefix, "/verifyRecoverPasswordCodeRequest"), function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var response, ex_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, mc_api.post("/mc/reset-password/verify-code", request)];
            case 1:
                response = _b.sent();
                return [2 /*return*/, { status: response.status }];
            case 2:
                ex_2 = _b.sent();
                return [2 /*return*/, { message: ex_2.message, data: (_a = ex_2 === null || ex_2 === void 0 ? void 0 : ex_2.response) === null || _a === void 0 ? void 0 : _a.data }];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var recoverPasswordRequest = createAsyncThunk("".concat(prefix, "/recoverPasswordRequest"), function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var response, ex_3;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, mc_api.post("/mc/reset-password", request)];
            case 1:
                response = _b.sent();
                return [2 /*return*/, { status: response.status }];
            case 2:
                ex_3 = _b.sent();
                return [2 /*return*/, { message: ex_3.message, data: (_a = ex_3 === null || ex_3 === void 0 ? void 0 : ex_3.response) === null || _a === void 0 ? void 0 : _a.data }];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var sendRecoverPasswordCodeRequest = createAsyncThunk("".concat(prefix, "/sendRecoverPasswordCodeRequest"), function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var response, ex_4;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, mc_api.post("/mc/reset-password/send-code", request)];
            case 1:
                response = _b.sent();
                return [2 /*return*/, { data: response.data }];
            case 2:
                ex_4 = _b.sent();
                return [2 /*return*/, { message: ex_4.message, data: (_a = ex_4 === null || ex_4 === void 0 ? void 0 : ex_4.response) === null || _a === void 0 ? void 0 : _a.data }];
            case 3: return [2 /*return*/];
        }
    });
}); });
