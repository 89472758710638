export var formatPhone = function (number) {
    if (!number)
        return "";
    var cleanNumber = number.replace(/[^\d]/g, "");
    return cleanNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3"); // (12) 1234-1234
};
export var formatTitle = function (text) {
    if (!text)
        return "";
    return text.replace(/#/g, "");
};
export function formatDescription(text) {
    if (!text)
        return "";
    var startTag = "<b>";
    var endTag = "</b>";
    var count = 0;
    // Exemplo de uso
    // Entrada 'Entre em #contato# com o nosso #suporte técnico pelos canais de atendimento abaixo:'
    // Saída: 'Entre em <b>contato</b> com o nosso <b>suporte técnico pelos canais de atendimento abaixo:</b>'
    // Loop pela string procurando o padrão "#"
    for (var i = 0; i <= text.length - 1; i++) {
        if (text[i] === "#") {
            // Se encontrou o padrão, adiciona a tag de negrito
            text = "".concat(text.slice(0, i)).concat(count % 2 === 0 ? startTag : endTag).concat(text.slice(i + 1));
            count++;
            i += startTag.length - 1; // pula a tag adicionada
        }
    }
    // adiciona a tag de fechamento de negrito se necessário
    if (count % 2 !== 0)
        text += endTag;
    return text;
}
