var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useFormik } from "formik";
import { Checkbox, Input, PasswordCheck, Select, DatePicker, InputFeedback, Button, } from "../../../components";
import { useApp } from "../../../context/app";
import { useToast } from "../../../hooks/toast";
import { useClientData } from "../../../hooks/clientData.hook";
import { validationRegisterSchema } from "./schema";
import { Terms } from "../styles";
// const s = ()
export var RegisterForm = function (props) {
    var onSubmit = props.onSubmit, loading = props.loading, disableSubmit = props.disableSubmit;
    var _a = useApp().state.register, serviceTerms = _a.serviceTerms, contractTerms = _a.contractTerms, privacyPolicy = _a.privacyPolicy;
    var _b = useState(null), date = _b[0], setDate = _b[1];
    var initalFormState = {
        firstName: "",
        lastName: "",
        birthDate: null,
        sex: "",
        nationality: "brasileiro",
        document: "",
        phone: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        termProcess: false,
        termService: false,
        termContact: false,
    };
    var formik = useFormik({
        initialValues: initalFormState,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: validationRegisterSchema,
        onSubmit: function (values, helpers) {
            onSubmit(__assign(__assign({}, values), { firstName: values.firstName.replace(/\s+/g, " ").trim(), lastName: values.lastName.replace(/\s+/g, " ").trim(), phone: values.phone.replace(")", ") "), birthDate: new Date(values.birthDate) }));
        },
    });
    var _c = useToast(), setToast = _c[1];
    var _d = useClientData(), clientIdUrl = _d.clientId, getItem = _d.getItem;
    var clientId = clientIdUrl || getItem("clientId");
    useEffect(function () {
        if (!clientId) {
            window.history.back();
        }
    }, [clientId]);
    var handleSubmit = formik.handleSubmit;
    var getError = function (key) {
        if (key === "password" &&
            formik.values.password.length > 0 &&
            (formik.values.password
                .toLowerCase()
                .includes(formik.values.firstName.toLowerCase()) ||
                formik.values.password
                    .toLowerCase()
                    .includes(formik.values.lastName.toLowerCase()))) {
            return "A senha não pode conter o nome ou sobrenome";
        }
        return formik.errors[key] && formik.touched[key] && formik.errors[key];
    };
    var getErrorFeedback = function (key) { return ({
        show: !!getError(key),
        text: getError(key),
        variation: "error",
    }); };
    var birthDateValue = !!formik.values.birthDate
        ? format(new Date(formik.values.birthDate), "yyy-MM-dd")
        : "";
    var disabledDocumentField = ["brasileiro", "estrangeiro"].includes(formik.values.document);
    var InputDocTypeBrazil = (_jsx(_Fragment, { children: _jsx(Input, { placeholder: " ", width: "100%", name: "document", label: "CPF", type: "text", mask: "999.999.999-99", 
            // disabled={disabledDocumentField}
            feedback: getErrorFeedback("document"), value: formik.values.document, onBlur: formik.handleBlur("document"), onChange: function (e) { return formik.setFieldValue("document", e.target.value); } }) }));
    var InputDocTypeForeign = (_jsx(_Fragment, { children: _jsx(Input, { placeholder: " ", width: "100%", name: "document", label: "Passaporte", type: "text", value: formik.values.document, onBlur: formik.handleBlur("document"), feedback: getErrorFeedback("document"), onChange: function (e) { return formik.setFieldValue("document", e.target.value); } }) }));
    var InputPhoneBrazil = (_jsx(Input, { placeholder: " ", width: "100%", name: "phone", label: "DDD + Telefone", type: "text", mask: "(99) 99999-9999", value: formik.values.phone, onBlur: formik.handleBlur("phone"), feedback: getErrorFeedback("phone"), onChange: function (e) {
            return formik.setFieldValue("phone", e.target.value.replace(" ", ""));
        } }));
    var InputPhoneForeign = (_jsx(Input, { placeholder: " ", width: "100%", name: "phone", label: "C\u00F3digo do Pa\u00EDs + DDD + Telefone", type: "text", value: formik.values.phone, onBlur: formik.handleBlur("phone"), feedback: getErrorFeedback("phone"), onChange: function (e) { return formik.setFieldValue("phone", e.target.value); } }));
    return (_jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(Input, { placeholder: " ", width: "100%", name: "firstName", label: "Nome", type: "text", value: formik.values.firstName, onBlur: formik.handleBlur("firstName"), feedback: getErrorFeedback("firstName"), onChange: function (e) { return formik.setFieldValue("firstName", e.target.value); } }), _jsx(Input, { placeholder: " ", width: "100%", name: "lastName", label: "Sobrenome", type: "text", value: formik.values.lastName, onBlur: formik.handleBlur("lastName"), feedback: getErrorFeedback("lastName"), onChange: function (e) { return formik.setFieldValue("lastName", e.target.value); } }), _jsx(DatePicker, { dateFormat: "dd/MM/yyyy", selected: formik.values.birthDate, name: "birthDate", label: "Data de nascimento", onBlur: formik.handleBlur("birthDate"), feedback: getErrorFeedback("birthDate"), onChange: function (e) { return formik.setFieldValue("birthDate", e); } }), _jsx(Select, { label: "Sexo", name: "sex", value: formik.values.sex, onBlur: formik.handleBlur("sex"), feedback: getErrorFeedback("sex"), onChange: function (e) { return formik.setFieldValue("sex", e.target.value); }, options: [
                    { name: "Selecione", value: "", selected: true, disabled: true },
                    { name: "Masculino", value: "M" },
                    { name: "Feminino", value: "F" },
                ] }), _jsx(Select, { label: "Nacionalidade", name: "nationality", value: formik.values.nationality, onBlur: formik.handleBlur("nationality"), feedback: getErrorFeedback("nationality"), onChange: function (e) {
                    formik.setFieldValue("nationality", e.target.value);
                    formik.setFieldValue("document", "");
                    formik.setFieldTouched("document", false);
                    formik.setFieldError("document", "");
                }, options: [
                    { name: "Selecione", value: "", selected: true, disabled: true },
                    { name: "Brasileiro", value: "brasileiro" },
                    { name: "Estrangeiro", value: "estrangeiro" },
                ] }), formik.values.nationality !== "estrangeiro"
                ? InputDocTypeBrazil
                : InputDocTypeForeign, formik.values.nationality !== "estrangeiro"
                ? InputPhoneBrazil
                : InputPhoneForeign, _jsx(Input, { placeholder: " ", width: "100%", name: "email", label: "E-mail", type: "text", value: formik.values.email, onBlur: formik.handleBlur("email"), feedback: getErrorFeedback("email"), onChange: function (e) { return formik.setFieldValue("email", e.target.value); } }), _jsx(Input, { placeholder: " ", width: "100%", name: "confirmEmail", label: "Confirme seu e-mail", type: "text", value: formik.values.confirmEmail, onBlur: formik.handleBlur("confirmEmail"), feedback: getErrorFeedback("confirmEmail"), onChange: function (e) { return formik.setFieldValue("confirmEmail", e.target.value); } }), _jsx(Input, { placeholder: " ", width: "100%", name: "password", label: "Senha", type: "password", value: formik.values.password, onBlur: formik.handleBlur("password"), feedback: getErrorFeedback("password"), onChange: function (e) { return formik.setFieldValue("password", e.target.value); }, viewValue: true }), _jsx(Input, { placeholder: " ", width: "100%", name: "confirmPassword", label: "Confirme sua senha", type: "password", value: formik.values.confirmPassword, onBlur: formik.handleBlur("confirmPassword"), feedback: getErrorFeedback("confirmPassword"), onChange: function (e) {
                    return formik.setFieldValue("confirmPassword", e.target.value);
                }, viewValue: true }), _jsx(PasswordCheck, { firstName: formik.values.firstName, lastName: formik.values.lastName, password: formik.values.password, confirmPassword: formik.values.confirmPassword }), _jsxs(Terms, { children: [_jsx(Checkbox, { checked: formik.values.termProcess, name: "termProcess", onChange: function (e) {
                            formik.setFieldTouched("termProcess", true);
                            formik.setFieldValue("termProcess", e.target.checked);
                        }, value: "Eu concordo que o Hospital Israelita Albert Einstein armazene e processe meus dados pessoais. <a style=\"font-family: inherit;\" href=\"".concat(serviceTerms, "\" target=\"_blank\">Termos de uso</a> e <a style=\"font-family: inherit;\" href=\"").concat(privacyPolicy, "\" target=\"_blank\">Pol\u00EDtica de privacidade</a>.") }), formik.values.termProcess === false && !!formik.touched.termProcess ? (_jsx("div", __assign({ style: { paddingLeft: 30 } }, { children: _jsx(InputFeedback, __assign({ variation: "error" }, { children: "\u00C9 necess\u00E1rio aceitar o termo de uso de dados" })) }))) : null, _jsx(Checkbox, { name: "termService", checked: formik.values.termService, onChange: function (e) {
                            formik.setFieldValue("termService", e.target.checked);
                            formik.setFieldTouched("termService", true);
                        }, value: "Termos do <a style=\"font-family: inherit;\" href=\"".concat(contractTerms, "\" target=\"_blank\">contrato de presta\u00E7\u00E3o</a> de servi\u00E7os.") }), formik.values.termService === false && !!formik.touched.termService ? (_jsx("div", __assign({ style: { paddingLeft: 30 } }, { children: _jsx(InputFeedback, __assign({ variation: "error" }, { children: "\u00C9 necess\u00E1rio aceitar o termo de servi\u00E7o" })) }))) : null] }), _jsx(Button, __assign({ type: "submit", disabled: !!(disableSubmit ||
                    formik.values.password
                        .toLowerCase()
                        .includes(formik.values.firstName.toLowerCase()) ||
                    formik.values.password
                        .toLowerCase()
                        .includes(formik.values.lastName.toLowerCase())), loading: !!loading }, { children: "Cadastrar" }))] })));
};
