var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { persistReducer } from "redux-persist";
import { createReducer } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { setRecoverPasswordOptions } from "./actions";
var persistConfig = {
    key: "recover-password",
    version: 1,
    storage: storage,
    whitelist: [],
};
var initialStateRecoverPassword = {};
export var recoverPasswordReducer = createReducer(initialStateRecoverPassword, function (builder) {
    builder.addCase(setRecoverPasswordOptions, function (state, action) { return (__assign(__assign({}, state), action)); });
});
export default persistReducer(persistConfig, recoverPasswordReducer);
