var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import InputMask from "react-input-mask";
import ReactDatePicker from "react-datepicker";
export var InputDatePicker = styled(ReactDatePicker)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid #8e959a;\n  border-radius: 8px;\n  min-width: 250px;\n  width: 100%;\n  padding: 15px 20px;\n  font-size: 16px;\n  outline: none;\n  transition: all 0.1s linear;\n  -webkit-transition: all 0.1s linear;\n  -moz-transition: all 0.1s linear;\n  :focus {\n    border: 1px solid #3951b2;\n  }\n\n  input {\n    width: 100%;\n  }\n"], ["\n  border: 1px solid #8e959a;\n  border-radius: 8px;\n  min-width: 250px;\n  width: 100%;\n  padding: 15px 20px;\n  font-size: 16px;\n  outline: none;\n  transition: all 0.1s linear;\n  -webkit-transition: all 0.1s linear;\n  -moz-transition: all 0.1s linear;\n  :focus {\n    border: 1px solid #3951b2;\n  }\n\n  input {\n    width: 100%;\n  }\n"])));
export var InputWithMask = styled(InputMask)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var InputContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  /* .react-datepicker-wrapper{ */\n  position: relative;\n  padding-top: 13px;\n  width: 100%;\n  label {\n    position: absolute;\n    top: 0;\n    font-size: 13px;\n    left: 15px;\n    color: #8e959a;\n    transition: all 0.1s linear;\n    -webkit-transition: all 0.1s linear;\n    -moz-transition: all 0.1s linear;\n    background-color: white;\n    padding: 5px;\n    box-sizing: border-box;\n  }\n\n  img {\n    position: absolute;\n    right: 5%;\n    top: 47%;\n    cursor: pointer;\n  }\n  input:focus + label,\n  input:not(:placeholder-shown) + label {\n    color: #3951b2;\n  }\n\n  &.is--success {\n    input {\n      border-color: #4caf50;\n    }\n\n    label {\n      color: #4caf50 !important;\n    }\n  }\n\n  &.is--error {\n    input {\n      border-color: #ff6347;\n    }\n\n    label {\n      color: #ff6347 !important;\n    }\n  }\n"], ["\n  /* .react-datepicker-wrapper{ */\n  position: relative;\n  padding-top: 13px;\n  width: 100%;\n  label {\n    position: absolute;\n    top: 0;\n    font-size: 13px;\n    left: 15px;\n    color: #8e959a;\n    transition: all 0.1s linear;\n    -webkit-transition: all 0.1s linear;\n    -moz-transition: all 0.1s linear;\n    background-color: white;\n    padding: 5px;\n    box-sizing: border-box;\n  }\n\n  img {\n    position: absolute;\n    right: 5%;\n    top: 47%;\n    cursor: pointer;\n  }\n  input:focus + label,\n  input:not(:placeholder-shown) + label {\n    color: #3951b2;\n  }\n\n  &.is--success {\n    input {\n      border-color: #4caf50;\n    }\n\n    label {\n      color: #4caf50 !important;\n    }\n  }\n\n  &.is--error {\n    input {\n      border-color: #ff6347;\n    }\n\n    label {\n      color: #ff6347 !important;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
