var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { useToast } from "../../../../hooks/toast";
import { Button, Banner, Input, Header } from "../../../../components";
import { FormSection, UpperSection, Wrapper } from "../../../Login/styles";
import { Title } from "../../../../assets/styles/default";
import { Content } from "../../../../assets/styles/GlobalStyles";
import { useFormik } from "formik";
import { useDispatch } from "../../../../redux/root/store";
import { recoverPasswordGetOptionsRequest, } from "../../redux/actions";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation, useNavigate } from "react-router-dom";
var ForgetPasswordDocument = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var navigate = useNavigate();
    var location = useLocation();
    var dispatch = useDispatch();
    var _b = useToast(), setToast = _b[1];
    var _c = location.state, title = _c.title, subtitle = _c.subtitle;
    var recoverPasswordTitle = title || "Recuperação de senha";
    var recoverPasswordSubtitle = subtitle || "Digite o documento vinculado a sua conta";
    var goBack = function () { return navigate(-1); };
    var formik = useFormik({
        initialValues: {
            valueForRecoverPassword: "",
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: function (values, _a) {
            var _b, _c, _d;
            var validateForm = _a.validateForm;
            return __awaiter(this, void 0, void 0, function () {
                var _e, email, phone, message, ex_1, message;
                return __generator(this, function (_f) {
                    switch (_f.label) {
                        case 0:
                            setLoading(true);
                            _f.label = 1;
                        case 1:
                            _f.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, dispatch(recoverPasswordGetOptionsRequest({
                                    document: values.valueForRecoverPassword,
                                })).then(unwrapResult)];
                        case 2:
                            _e = _f.sent(), email = _e.email, phone = _e.phone, message = _e.message;
                            if (message) {
                                setToast({
                                    show: true,
                                    type: "error",
                                    message: message,
                                });
                            }
                            else if (!email && !phone) {
                                setToast({
                                    show: true,
                                    type: "error",
                                    message: "Não econtramos nenhuma informação vinculada a este documento.",
                                });
                            }
                            else {
                                navigate("/recover-password/options", {
                                    state: {
                                        document: values.valueForRecoverPassword,
                                        email: email,
                                        phone: phone,
                                    },
                                });
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            ex_1 = _f.sent();
                            message = (_d = (_c = (_b = ex_1 === null || ex_1 === void 0 ? void 0 : ex_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : ex_1.message;
                            setToast({
                                show: true,
                                type: "error",
                                message: message,
                            });
                            return [3 /*break*/, 4];
                        case 4:
                            setLoading(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    });
    return (_jsx(Content, { children: _jsxs(Wrapper, { children: [_jsx(Header, {}), _jsx(FormSection, { children: _jsxs(UpperSection, { children: [_jsxs("div", __assign({ style: { marginBottom: "20px", fontSize: "14px" } }, { children: [_jsx(Title, { children: recoverPasswordTitle }), _jsx("p", { children: recoverPasswordSubtitle })] })), _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(Input, { width: "100%", name: "document", type: "text", placeholder: "Digite seu CPF ou passaporte", label: "CPF ou Passaporte", value: formik.values.valueForRecoverPassword, onBlur: formik.handleBlur("valueForRecoverPassword"), onChange: function (e) {
                                            return formik.setFieldValue("valueForRecoverPassword", e.target.value);
                                        } }), _jsx(Button, __assign({ type: "submit", disabled: !formik.values.valueForRecoverPassword, loading: loading }, { children: "Redefinir senha" })), _jsx(Button, __assign({ onClick: goBack, variation: "outline", type: "button" }, { children: "Voltar" }))] }))] }) }), _jsx(Banner, {})] }) }));
};
export default ForgetPasswordDocument;
