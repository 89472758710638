var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { InputFeedback } from "../../components";
import { SelectContent, SelectStyled } from "./styles";
export var Select = function (_a) {
    var options = _a.options, value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, name = _a.name, label = _a.label, _b = _a.feedback, feedback = _b === void 0 ? { show: false, text: "", variation: "info" } : _b;
    var showFeedback = feedback.show, feedbackText = feedback.text, feedbackVariation = feedback.variation;
    return (_jsxs(SelectContent, __assign({ className: "is--".concat(showFeedback ? "error" : "info") }, { children: [_jsx(SelectStyled, __assign({ value: value, name: name, onBlur: onBlur, onChange: function (e) { return onChange(e); } }, { children: options.map(function (option, index) { return (_jsx("option", __assign({ selected: option.selected, disabled: option.disabled, value: option.value }, { children: option.name }), index)); }) })), _jsx("label", { children: label }), showFeedback ? (_jsx(InputFeedback, __assign({ variation: feedbackVariation }, { children: feedbackText }))) : null] })));
};
