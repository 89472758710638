var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Content } from "../../assets/styles/GlobalStyles";
export var Main = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  max-width: 600px;\n  background-color: #fff;\n  border-radius: 25px;\n  margin: auto;\n  h2 {\n    margin-bottom: 8px;\n  }\n  p {\n    letter-spacing: 0.16px;\n    margin-bottom: 32px;\n  }\n  img {\n    max-width: 170px;\n  }\n  /* @media (max-width: 1028px) { */\n  /* width: 100%; */\n  /* padding: 0px 32px 93px 32px; */\n  /* } */\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  max-width: 600px;\n  background-color: #fff;\n  border-radius: 25px;\n  margin: auto;\n  h2 {\n    margin-bottom: 8px;\n  }\n  p {\n    letter-spacing: 0.16px;\n    margin-bottom: 32px;\n  }\n  img {\n    max-width: 170px;\n  }\n  /* @media (max-width: 1028px) { */\n  /* width: 100%; */\n  /* padding: 0px 32px 93px 32px; */\n  /* } */\n"])));
export var FormContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 30px 0 60px;\n\n  @media (min-width: 600px) {\n    padding: 30px 40px;\n  }\n"], ["\n  padding: 30px 0 60px;\n\n  @media (min-width: 600px) {\n    padding: 30px 40px;\n  }\n"])));
export var Terms = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 30px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"], ["\n  margin-top: 30px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"])));
export var Container = styled(Content)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: #fff;\n  padding: 80px 30px 0;\n\n  @media (min-width: 600px) {\n    background-color: #f0f2f4;\n    padding-bottom: 30px;\n  }\n"], ["\n  background-color: #fff;\n  padding: 80px 30px 0;\n\n  @media (min-width: 600px) {\n    background-color: #f0f2f4;\n    padding-bottom: 30px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
