var colors = {
    defaultBlue: "#004F92",
    white: "#fff",
    grey: "#666666",
    lightGrey: "#00000033",
    mediumGrey: "#8E959A",
    darkGrey: "#373F45",
    black: "#000000",
    defaultGrey: "#B3B3B3",
};
var themes = {
    colors: colors,
    fonts: {
        title: {
            fontSize: "24px",
            color: colors.black,
            fontWeight: 600,
            fontStyle: "normal",
            lineSpacing: "0px",
            fontFamily: "Montserrat, system-ui",
        },
        subtitle: {
            fontSize: "18px",
            color: colors.black,
            fontWeight: 400,
            fontStyle: "normal",
            lineSpacing: "0px",
            fontFamily: "Montserrat, system-ui",
        },
    },
};
export default themes;
