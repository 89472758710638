var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from "react";
import { InputFeedback } from "../../components";
import ViewOn from "../../assets/icons/view-on.svg";
import ViewOff from "../../assets/icons/view-off.svg";
import { InputStyle, InputContent, InputMaskedStyled } from "./styles";
export var Input = function (_a) {
    var placeholder = _a.placeholder, width = _a.width, name = _a.name, label = _a.label, type = _a.type, value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, mask = _a.mask, _b = _a.viewValue, viewValue = _b === void 0 ? false : _b, _c = _a.required, required = _c === void 0 ? false : _c, _d = _a.minLength, minLength = _d === void 0 ? 1 : _d, _e = _a.maxLength, maxLength = _e === void 0 ? 100 : _e, _f = _a.feedback, feedback = _f === void 0 ? { show: false, text: "", variation: "info" } : _f;
    var inputRef = useRef(null);
    var showFeedback = feedback.show, feedbackText = feedback.text, feedbackVariation = feedback.variation;
    var auxiliarProps = !!mask ? {} : { minLength: minLength, maxLength: maxLength };
    var inputProps = __assign({ className: "is--".concat(showFeedback ? "error" : "info"), placeholder: placeholder, width: width, name: name, ref: inputRef, type: type, value: value, onChange: onChange, onBlur: onBlur, required: required, mask: mask }, auxiliarProps);
    var InputComponent = !!mask ? (_jsx(InputMaskedStyled, __assign({}, inputProps))) : (_jsx(InputStyle, __assign({}, inputProps)));
    var _g = React.useState(false), visualize = _g[0], setVisualize = _g[1];
    var viewIcon = visualize ? ViewOn : ViewOff;
    return (_jsxs(InputContent, { children: [InputComponent, _jsx("label", { children: label }), viewValue ? (_jsx("button", __assign({ type: "button", onClick: function () {
                    setVisualize(!visualize);
                    inputRef.current.type =
                        inputRef.current.type === "password" ? "text" : "password";
                } }, { children: _jsx("img", { src: viewIcon, alt: "view" }) }))) : (""), showFeedback ? (_jsx(InputFeedback, __assign({ variation: feedbackVariation }, { children: feedbackText }))) : null] }));
};
