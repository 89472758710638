export var MFA_STATUS;
(function (MFA_STATUS) {
    MFA_STATUS["MISSING_PARAMETERS"] = "MISSING_PARAMETERS";
    MFA_STATUS["INITIAL"] = "INITIAL";
    MFA_STATUS["WAIT_CODE"] = "WAIT_CODE";
    MFA_STATUS["WRONG_CODE"] = "WRONG_CODE";
    MFA_STATUS["TIME_EXPIRED"] = "TIME_EXPIRED";
    MFA_STATUS["NEED_A_NEW_CODE"] = "NEED_A_NEW_CODE";
    MFA_STATUS["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
    MFA_STATUS["ALLOW"] = "ALLOW";
})(MFA_STATUS || (MFA_STATUS = {}));
export var MFA_PAGE;
(function (MFA_PAGE) {
    MFA_PAGE["SELECT_COMMUNICATION_WAY"] = "SELECT_COMMUNICATION_WAY";
    MFA_PAGE["INSERT_CODE"] = "INSERT_CODE";
    MFA_PAGE["CODE_NO_MATCH"] = "CODE_NO_MATCH";
    MFA_PAGE["DONE"] = "DONE";
})(MFA_PAGE || (MFA_PAGE = {}));
export var MFA_SELECTED_WAY;
(function (MFA_SELECTED_WAY) {
    MFA_SELECTED_WAY["EMAIL"] = "EMAIL";
    MFA_SELECTED_WAY["SMS"] = "SMS";
})(MFA_SELECTED_WAY || (MFA_SELECTED_WAY = {}));
export var MFA_REASON;
(function (MFA_REASON) {
    MFA_REASON["GENERIC"] = "GENERIC";
    MFA_REASON["UPDATE_LOGIN"] = "UPDATE_LOGIN";
    MFA_REASON["UPDATE_EMAIL"] = "UPDATE_EMAIL";
})(MFA_REASON || (MFA_REASON = {}));
