var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import App from "./App";
import { AppProvider } from "./context/app";
import { ToastProvider } from "./context/toast";
import { fetchAndActivate } from "firebase/remote-config";
import remoteConfig from "./hooks/RemoteConfig/remoteConfig.hook";
export default function Root(props) {
    var firebasePromise = React.useMemo(function () {
        return fetchAndActivate(remoteConfig);
    }, [remoteConfig]);
    return (_jsx(AppProvider, { children: _jsx(ToastProvider, { children: _jsx(App, __assign({ firebase: firebasePromise }, props)) }) }));
}
