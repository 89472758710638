var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState, } from "react";
import { Banner, Button, Input, Header } from "../../../../components";
import { Content } from "../../../../assets/styles/GlobalStyles";
import { FormSection, Wrapper } from "../../../Login/styles";
import { useDispatch } from "../../../../redux/root/store";
import { getMFAGenerateSelectedWay, getMFAStatus, getMFAVerificateKey, getUrlVerificationCode, } from "../../../MFA/redux/selectors";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { MFA_SELECTED_WAY, MFA_STATUS } from "../../constants";
import { maskMail, maskPhone } from "../../helpers";
import { verifyRecoverPasswordCodeRequest } from "../../redux/actions";
import { setMFAStatus, setMFAWait } from "../../../MFA/redux/actions";
import { DigitsGroup, DigitsLine, LineBarBlue, LineBarContent, LineBarGrey, Paragraph, ParagraphAlert, ParagraphSmaller, TextHiperlink, Timer, ViewDetails, ViewResendCode, ViewResendCodeColumn, ViewTimer, } from "./styles";
import colors from "../../../../assets/colors";
import { useToast } from "../../../../hooks/toast";
import { useLocation, useNavigate } from "react-router-dom";
var RecoverPasswordValidation = function () {
    var Ref = useRef(0);
    var _a = useState(""), timer = _a[0], setTimer = _a[1];
    var _b = useState(""), codes = _b[0], setCodes = _b[1];
    var _c = useState(false), isCodeError = _c[0], setIsCodeError = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var dispatch = useDispatch();
    var location = useLocation();
    var navigate = useNavigate();
    var _e = useToast(), setToast = _e[1];
    // const getWait = useSelector(getMFAWait);
    var getStatus = useSelector(getMFAStatus);
    var getKey = useSelector(getMFAVerificateKey);
    var getSelectedWay = useSelector(getMFAGenerateSelectedWay);
    var getUrlVerification = useSelector(getUrlVerificationCode);
    var params = location.state;
    var _f = useState(params.expiresInSecconds), timerCount = _f[0], setTimerCount = _f[1];
    var user = {
        email: params.email,
        phone: params.phone,
    };
    var goBack = function () { return navigate(-1); };
    function TextSelectedWay(_a) {
        var _b;
        var way = _a.way;
        var email = maskMail((user === null || user === void 0 ? void 0 : user.email) || "");
        var phone = maskPhone((user === null || user === void 0 ? void 0 : user.phone) || "");
        var wayText = (_b = {},
            _b[MFA_SELECTED_WAY.EMAIL] = "para ".concat(email, "."),
            _b[MFA_SELECTED_WAY.SMS] = "via SMS para ".concat(phone, "."),
            _b.default = "para voc\u00EA.",
            _b);
        return (_jsxs(Paragraph, { children: ["Digite o c\u00F3digo de ", _jsxs("strong", { children: [params.digitsLenght, " d\u00EDgitos"] }), " que enviamos", " ", wayText[way || "default"]] }));
    }
    var verifyCode = useCallback(function (codes) { return __awaiter(void 0, void 0, void 0, function () {
        var getCode;
        return __generator(this, function (_a) {
            getCode = codes;
            if (getCode.length !== params.digitsLenght) {
                return [2 /*return*/];
            }
            setLoading(true);
            dispatch(setMFAStatus(MFA_STATUS.WAIT_CODE));
            dispatch(setMFAWait(true));
            setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var _a, message, status, data, ex_1, message;
                var _b, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            _e.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, dispatch(verifyRecoverPasswordCodeRequest({
                                    key: params.key,
                                    code: getCode,
                                })
                                // @ts-ignore
                                ).then(unwrapResult)];
                        case 1:
                            _a = _e.sent(), message = _a.message, status = _a.status, data = _a.data;
                            if (status === 202) {
                                navigate("/recover-password/new-password", {
                                    state: {
                                        code: getCode,
                                        key: params.key,
                                    },
                                });
                            }
                            else if (data.type === "MFA_INVALID")
                                dispatch(setMFAStatus(MFA_STATUS.WRONG_CODE));
                            else if (message)
                                setToast({
                                    show: true,
                                    type: "error",
                                    message: message,
                                });
                            return [3 /*break*/, 3];
                        case 2:
                            ex_1 = _e.sent();
                            message = (_d = (_c = (_b = ex_1 === null || ex_1 === void 0 ? void 0 : ex_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : ex_1.message;
                            setToast({
                                show: true,
                                type: "error",
                                message: message,
                            });
                            return [3 /*break*/, 3];
                        case 3:
                            setLoading(false);
                            dispatch(setMFAWait(false));
                            return [2 /*return*/];
                    }
                });
            }); }, 1000);
            return [2 /*return*/];
        });
    }); }, [dispatch, getKey, getUrlVerification]);
    var onClickReset = useCallback(function () {
        dispatch(setMFAWait(false));
        setIsCodeError(false);
        clearTimer(getDeadTime().toISOString());
        setTimerCount(params.expiresInSecconds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var goBackCommunicationWay = useCallback(function () {
        onClickReset();
        navigate(-1);
    }, [dispatch, onClickReset]);
    function getDeadTime() {
        var deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + params.expiresInSecconds);
        return deadline;
    }
    function getTimeRemaining(iso) {
        var total = Date.parse(iso) - Date.parse(new Date().toISOString());
        var seconds = Math.floor((total / 1000) % 60).toString();
        var minutes = Math.floor((total / 1000 / 60) % 60).toString();
        var hours = Math.floor((total / 1000 / 60 / 60) % 24).toString();
        return { total: total, hours: hours, minutes: minutes, seconds: seconds };
    }
    var startTimer = useCallback(function (iso) {
        var _a = getTimeRemaining(iso), total = _a.total, minutes = _a.minutes, seconds = _a.seconds;
        if (total > -1) {
            setTimer("".concat(minutes.padStart(2, "0"), ":").concat(seconds.padStart(2, "0")));
        }
        else {
            // dispatchActionCode('expirado');
            clearInterval(Ref.current);
            setTimeout(function () { return setTimerCount(0); }, 1000);
            setIsCodeError(true);
            dispatch(setMFAWait(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var clearTimer = useCallback(function (iso) {
        setTimer("00:00");
        var total = getTimeRemaining(iso).total;
        if (total > -1) {
            if (Ref.current) {
                clearInterval(Ref.current);
            }
            Ref.current = setInterval(function () {
                startTimer(iso);
                setTimerCount(function (prev) {
                    return prev - 1;
                });
            }, 1000);
        }
    }, [startTimer, setTimerCount]);
    function timerCountPercent(time) {
        return Math.ceil((time / params.expiresInSecconds) * 100).toFixed(0) + "%";
    }
    var messageCodeError = function (code) {
        var message;
        (function (message) {
            message["INITIAL"] = "";
            message["ALLOW"] = "";
            message["WAIT_CODE"] = "";
            message["WRONG_CODE"] = "C\u00F3digo incorreto";
            message["TIME_EXPIRED"] = "C\u00F3digo expirado";
            message["NEED_A_NEW_CODE"] = "Por favor, solicite um novo c\u00F3digo";
            message["UNKNOWN_ERROR"] = "N\u00E3o foi poss\u00EDvel validar o c\u00F3digo";
        })(message || (message = {}));
        return message[code] || "";
    };
    var checkDoubleOptions = function (user) {
        if (user.email && user.phone) {
            return true;
        }
        else {
            return false;
        }
    };
    useEffect(function () {
        var abortController = new AbortController();
        onClickReset();
        return function () {
            clearInterval(Ref.current);
            Ref.current = 0;
            setTimerCount(0);
            abortController.abort();
        };
    }, [onClickReset]);
    return (_jsx(Content, { children: _jsxs(Wrapper, { children: [_jsx(Header, {}), _jsxs(FormSection, { children: [_jsx(TextSelectedWay, { way: getSelectedWay }), _jsx(DigitsGroup, { children: _jsx(DigitsLine, { children: _jsx(Input, { name: "code", label: "C\u00F3digo", placeholder: "", value: codes, type: "text", onChange: function (e) { return setCodes(e.target.value); }, width: "100%", maxLength: 6 }) }) }), _jsx(ViewResendCode, { children: _jsxs(ViewResendCodeColumn, { children: [_jsx(ParagraphAlert, { children: messageCodeError(getStatus) }), timerCount < params.expiresInSecconds - 27 ? (_jsx(TextHiperlink, __assign({ onClick: goBackCommunicationWay, textAlign: "center" }, { children: "Reenviar c\u00F3digo" }))) : null] }) }), _jsxs(ViewTimer, { children: [_jsx(ParagraphSmaller, { children: "Tempo expira em " }), _jsx(Timer, { children: timer }), _jsx(LineBarContent, __assign({ width: 200 }, { children: _jsx(LineBarGrey, __assign({ width: 200 }, { children: _jsx(LineBarBlue, { width: timerCountPercent(timerCount) }) })) }))] }), _jsx(ViewDetails, { children: timerCount < params.expiresInSecconds - 27 ? (_jsxs(ParagraphSmaller, { children: ["N\u00E3o recebeu o c\u00F3digo?", " ", _jsx(TextHiperlink, __assign({ color: colors.GREY_SECONDARY, textDecoration: "none", onClick: goBackCommunicationWay }, { children: checkDoubleOptions(user)
                                            ? "Escolha outra opção de envio"
                                            : "Tentar novamente." }))] })) : null }), _jsx(Button, __assign({ loading: loading, disabled: codes.length === 6 ? false : true, onClick: function () { return verifyCode(codes); } }, { children: "Pr\u00F3ximo" })), _jsx(Button, __assign({ variation: "outline", type: "button", onClick: goBack }, { children: "Voltar" }))] }), _jsx(Banner, {})] }) }));
};
export default RecoverPasswordValidation;
