import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import { useApp } from "../../context/app";
import { BannerStyled } from "./styles";
var BannerComponent = function () {
    var state = useApp().state;
    var backgroundImage = state.login.backgroundImage;
    return _jsx(BannerStyled, { backgroundImage: backgroundImage });
};
export var Banner = memo(BannerComponent);
