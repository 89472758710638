var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect } from "react";
import { Banner, Button, Header } from "../../../../components";
import { Content } from "../../../../assets/styles/GlobalStyles";
import { FormSection, Wrapper } from "../../../Login/styles";
import { LabelEmail, OptionCardContainer, OptionContentContainer, Paragraph, ParagraphChoice, RadioBorderExternal, RadioBorderInternalCircle, RadioBorderInternalWhite, RadioColumnSelect, RadioColumnText, Title, } from "./styles";
import { useDispatch } from "../../../../redux/root/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { setMFAGenerateSelected, setMFAStatus, } from "../../../MFA/redux/actions";
import { MFA_SELECTED_WAY, MFA_STATUS } from "../../constants";
import { maskMail, maskPhone } from "../../helpers";
import { getMFAGenerateSelectedWay, } from "../../../MFA/redux/selectors";
import { useSelector } from "react-redux";
import { sendRecoverPasswordCodeRequest } from "../../redux/actions";
import { useToast } from "../../../../hooks/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { CentralizeDivider, Padding } from "../../../Migrated/styles";
var RecoverPasswordOptions = function () {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useToast(), setToast = _b[1];
    var getSelectedWay = useSelector(getMFAGenerateSelectedWay);
    var user = location.state;
    var goBack = function () { return navigate(-1); };
    function RadioButtonSelect(_a) {
        var _b = _a.selected, selected = _b === void 0 ? false : _b;
        return (_jsx(RadioBorderExternal, __assign({ selected: selected }, { children: _jsx(RadioBorderInternalWhite, { children: _jsx(RadioBorderInternalCircle, { selected: selected }) }) })));
    }
    function CardSelect(_a) {
        var _b = _a.selected, selected = _b === void 0 ? false : _b, _c = _a.Icon, Icon = _c === void 0 ? null : _c, _d = _a.label, label = _d === void 0 ? "" : _d, _e = _a.value, value = _e === void 0 ? "" : _e, onPress = _a.onPress;
        return (_jsxs(OptionCardContainer, __assign({ onClick: onPress }, { children: [_jsx(OptionContentContainer, { children: _jsxs(RadioColumnText, { children: [_jsx(ParagraphChoice, { children: label }), _jsx(LabelEmail, { children: value })] }) }), _jsx(RadioColumnSelect, { children: _jsx(RadioButtonSelect, { selected: selected }) })] })));
    }
    var WelcomeMFA = function (params) {
        var user = params.user;
        var options = { email: Boolean(user.email), sms: Boolean(user.phone) };
        var messageShowAll = function () { return (_jsxs(_Fragment, { children: [_jsx(Title, { children: "Por onde voc\u00EA deseja receber a chave de autentica\u00E7\u00E3o?" }), _jsx(Paragraph, { children: "Enviaremos um c\u00F3digo de acesso para o meio de contato escolhido." })] })); };
        var messageEmail = function () { return (_jsx(Title, { children: "Enviaremos uma chave de autentica\u00E7\u00E3o para o seu endere\u00E7o de e-mail." })); };
        var messageSMS = function () { return (_jsx(Title, { children: "Enviaremos uma chave de autentica\u00E7\u00E3o para o seu n\u00FAmero de celular." })); };
        var MessageComponent = function (_a) {
            var _b = _a.options, email = _b.email, sms = _b.sms;
            if (email && sms) {
                return messageShowAll();
            }
            else if (email) {
                return messageEmail();
            }
            else if (sms) {
                return messageSMS();
            }
            else {
                return null;
            }
        };
        return _jsx(MessageComponent, { options: options });
    };
    function SelectWayRadio(params) {
        var _this = this;
        var user = params.user;
        var getSelectedWay = useSelector(getMFAGenerateSelectedWay);
        var setSelectedToOneSingleWay = useCallback(function (way) { return getSelectedWay === way; }, []);
        var onSelect = useCallback(function (value) {
            return function () { return __awaiter(_this, void 0, void 0, function () {
                var getCurrentWay;
                return __generator(this, function (_a) {
                    getCurrentWay = getSelectedWay === value ? "" : value;
                    dispatch(setMFAGenerateSelected(getCurrentWay));
                    return [2 /*return*/];
                });
            }); };
        }, [dispatch]);
        useEffect(function () {
            dispatch(setMFAStatus(MFA_STATUS.INITIAL));
        }, []);
        return (_jsxs(_Fragment, { children: [(user === null || user === void 0 ? void 0 : user.email) ? (_jsx(CardSelect
                // Icon={MFAOptionEmail}
                , { 
                    // Icon={MFAOptionEmail}
                    selected: setSelectedToOneSingleWay(MFA_SELECTED_WAY.EMAIL), label: "E-mail", value: maskMail((user === null || user === void 0 ? void 0 : user.email) || ""), onPress: onSelect(MFA_SELECTED_WAY.EMAIL) })) : null, (user === null || user === void 0 ? void 0 : user.phone) ? (_jsx(CardSelect
                // Icon={MFAOptionSMS}
                , { 
                    // Icon={MFAOptionSMS}
                    selected: setSelectedToOneSingleWay(MFA_SELECTED_WAY.SMS), label: "Celular SMS", value: maskPhone((user === null || user === void 0 ? void 0 : user.phone) || ""), onPress: onSelect(MFA_SELECTED_WAY.SMS) })) : null] }));
    }
    var nextPage = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, message, data, ex_1, message;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __generator(this, function (_l) {
            switch (_l.label) {
                case 0:
                    setLoading(true);
                    _l.label = 1;
                case 1:
                    _l.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dispatch(sendRecoverPasswordCodeRequest({
                            sendTo: getSelectedWay,
                            document: user.document,
                        })
                        // @ts-ignore
                        ).then(unwrapResult)];
                case 2:
                    _a = _l.sent(), message = _a.message, data = _a.data;
                    if (((_c = (_b = data === null || data === void 0 ? void 0 : data.sms) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.message) === "INTERNATIONAL_NUMBER_NOT_ALLOWED")
                        message = "Não é possível enviar SMS para números internacionais.";
                    if ((_e = (_d = data === null || data === void 0 ? void 0 : data.email) === null || _d === void 0 ? void 0 : _d.response) === null || _e === void 0 ? void 0 : _e.message)
                        message = (_g = (_f = data === null || data === void 0 ? void 0 : data.email) === null || _f === void 0 ? void 0 : _f.response) === null || _g === void 0 ? void 0 : _g.message;
                    if (message) {
                        setToast({
                            show: true,
                            type: "error",
                            message: message,
                        });
                    }
                    else {
                        navigate("/recover-password/validation", {
                            state: __assign(__assign({ document: user.document }, data), { email: user.email, phone: user.phone }),
                        });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    ex_1 = _l.sent();
                    message = (_k = (_j = (_h = ex_1 === null || ex_1 === void 0 ? void 0 : ex_1.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.message) !== null && _k !== void 0 ? _k : ex_1.message;
                    setToast({
                        show: true,
                        type: "error",
                        message: message,
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, getSelectedWay, navigate, user]);
    return (_jsx(Content, { children: _jsxs(Wrapper, { children: [_jsx(Header, {}), _jsxs(FormSection, { children: [_jsx(WelcomeMFA, { user: user }), _jsx(SelectWayRadio, { user: user }), _jsx(Button, __assign({ disabled: !getSelectedWay, loading: loading, onClick: nextPage }, { children: "Pr\u00F3ximo" })), _jsxs(CentralizeDivider, { children: [_jsx(Padding, __assign({ paddingBottom: 0 }, { children: "Est\u00E1 com dificuldade para redefinir sua senha ou acessar?" })), _jsx(Button, __assign({ variation: "outline", onClick: function () { return navigate("/support"); } }, { children: "Fale com o suporte" }))] })] }), _jsx(Banner, {})] }) }));
};
export default RecoverPasswordOptions;
