var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
var contextInitialState = {
    message: "",
    type: "info",
    show: false,
    delay: 4000,
};
function appReducer(state, action) {
    switch (action.type) {
        case "set-toast": {
            return __assign(__assign({}, state), action.payload);
        }
        default: {
            throw new Error("Unhandled action type: ".concat(action.type));
        }
    }
}
export var ToastStateContext = React.createContext(undefined);
export function ToastProvider(_a) {
    var children = _a.children;
    var _b = React.useReducer(appReducer, contextInitialState), state = _b[0], dispatch = _b[1];
    var value = { state: state, dispatch: dispatch };
    return (_jsx(ToastStateContext.Provider, __assign({ value: value }, { children: children })));
}
