var NOT_ALLOWED_LIST = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
    "12345678909",
];
var STRICT_STRIP_REGEX = /[.-]/g;
var LOOSE_STRIP_REGEX = /[^\d]/g;
var verifierDigit = function (digits) {
    var numbers = digits.split("").map(function (number) { return parseInt(number, 10); });
    var modulus = numbers.length + 1;
    var multiplied = numbers.map(function (number, index) { return number * (modulus - index); });
    var mod = multiplied.reduce(function (buffer, number) { return buffer + number; }) % 11;
    return mod < 2 ? 0 : 11 - mod;
};
var strip = function (number, strict) {
    var regex = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
    return (number || "").replace(regex, "");
};
export var format = function (number) {
    return strip(number).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
};
export var isValidCpf = function (number, strict) {
    var stripped = strip(number, strict);
    // CPF must be defined
    if (!stripped) {
        return false;
    }
    // CPF must have 11 chars
    if (stripped.length !== 11) {
        return false;
    }
    // CPF can't be not-allowed list
    if (NOT_ALLOWED_LIST.includes(stripped)) {
        return false;
    }
    var numbers = stripped.substr(0, 9);
    numbers += verifierDigit(numbers);
    numbers += verifierDigit(numbers);
    return numbers.substr(-2) === stripped.substr(-2);
};
