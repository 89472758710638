import { getRemoteConfig } from "firebase/remote-config";
import { firebaseConnection } from "../../firebase";
var remoteConfig = getRemoteConfig(firebaseConnection);
remoteConfig.settings.minimumFetchIntervalMillis = 7500;
remoteConfig.defaultConfig = {
    login: {
        enabled: false,
        title: "",
        description: "",
        contact: {
            phone: "",
            link_chat_whatsapp: "",
        },
    },
    immediateCare: {
        enabled: false,
        title: "",
        description: "",
        contact: {
            phone: "",
            link_chat_whatsapp: "",
        },
    },
};
export default remoteConfig;
