var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from "react";
import { useQueryParams } from "./queryParams.hook";
import { useLocalStorage } from "./localstorage.hook";
export function useClientData() {
    var _a = useState({}), params = _a[0], setParam = _a[1];
    var storage = useLocalStorage();
    var queryParams = useQueryParams();
    return __assign(__assign({}, storage), queryParams);
}
