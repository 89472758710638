var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useApp } from "../../context/app";
import { useToast } from "../../hooks/toast";
import { Banner, Header, Input, Button } from "../../components";
import { useClientData } from "../../hooks/clientData.hook";
import RequalificationModal from "./Partial/RequalificationModal";
import ResendActiveAccountModal from "./Partial/ResendActiveEmailModal";
import { getRedirectUrl } from "./helper";
import { validationLoginSchema } from "./schema";
import { errorException } from "./constants";
import { TitleLogin, UpperSection, Wrapper, BottomSection, FormSection, } from "./styles";
import { mc_api } from "../../infra/http/axios-http-client";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";
import { ErrorCode } from "./enums/ErrorCode";
import TagManager from "react-gtm-module";
export default function LoginComponent() {
    var _this = this;
    var catchSystemError = getRemoteConfigValue("catch_system_error");
    var navigate = useNavigate();
    var _a = useToast(), setToast = _a[1];
    var appState = useApp().state;
    var _b = useState({
        document: "",
        password: "",
        loading: false,
        showModal: false,
        showTypeModal: "",
    }), state = _b[0], setState = _b[1];
    var _c = useClientData(), _d = _c.clientId, clientId = _d === void 0 ? appState.login.clientId : _d, redirect = _c.redirect;
    var windowRedirect = clientId === "reabilitacao-web"
        ? "L3RlbGVyZWFiaWxpdGFjYW8tZGFzaGJvYXJk"
        : appState.login.defaultRedirect;
    function handleSubmitForm(values) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (clientId === "conecta-web") {
                            if ((_a = catchSystemError === null || catchSystemError === void 0 ? void 0 : catchSystemError.login) === null || _a === void 0 ? void 0 : _a.enabled) {
                                window.location.href = "/warning-screen";
                                return [2 /*return*/];
                            }
                        }
                        return [4 /*yield*/, mc_api
                                .post("/mc/auth/login", __assign(__assign({}, values), { appOrigin: clientId }))
                                .then(function (loginRes) { return __awaiter(_this, void 0, void 0, function () {
                                var response, data, url, queryString, urlParams;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, mc_api.get("/mc/auth/token", {
                                                headers: {
                                                    Authorization: "Bearer ".concat(loginRes.data.patientToken),
                                                },
                                            })];
                                        case 1:
                                            response = _a.sent();
                                            data = response.data;
                                            url = getRedirectUrl(data, "", values.windowRedirect).url;
                                            localStorage.setItem("api_token", data.token);
                                            localStorage.setItem("api_patientToken", data.patientToken);
                                            localStorage.setItem("token", data.token);
                                            localStorage.setItem("patientToken", data.patientToken);
                                            queryString = window.location.search;
                                            urlParams = new URLSearchParams(queryString);
                                            if (atob(urlParams.get("redirect")) == "/app-payment") {
                                                window.location.replace("/app-payment");
                                            }
                                            else {
                                                window.location.replace(url);
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); })
                                .catch(function (err) {
                                var _a;
                                var _b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data, statusCode = _b.statusCode, error = _b.error, message = _b.message, type = _b.type;
                                loginErrorTakeAction({
                                    document: values.document,
                                    statusCode: statusCode,
                                    error: error,
                                    message: message,
                                    type: type,
                                });
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function loginErrorTakeAction(data) {
        var defaultErrMessage = "Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde.";
        var document = data.document, statusCode = data.statusCode, error = data.error, message = data.message;
        var shouldRedirectToPasswordRecover = [
            ErrorCode.LOGIN_ATTEMPTS_EXCEEDED,
            ErrorCode.EXPIRED_PASSWORD,
        ].includes(error);
        message =
            error && message
                ? statusCode >= 500
                    ? "[".concat(error, "] ").concat(message)
                    : message
                : defaultErrMessage;
        if (statusCode === 504) {
            setToast({
                show: true,
                type: "error",
                message: defaultErrMessage,
            });
        }
        else if (error === errorException.COGNITO_LOGIN_RESET_REQUIRED) {
            message = null;
            navigate("/recover-password/reset", { state: { document: document } });
            return;
        }
        else if (error === errorException.COGNITO_ACCOUNT_UNCONFIRMED) {
            message = null;
            setState(__assign(__assign({}, state), { showModal: true, loading: false, document: document, showTypeModal: "active-account" }));
            return;
        }
        else if (shouldRedirectToPasswordRecover) {
            navigate("/recover-password/document", {
                state: {
                    document: document,
                    title: "Atualização de segurança",
                    subtitle: "Digite o documento que você utiliza para acessar sua conta e iniciaremos as etapas para redefinição de senha.",
                },
            });
        }
        setToast({
            show: true,
            type: "error",
            message: message,
        });
        setState(__assign(__assign({}, state), { loading: false, document: document, showModal: false }));
    }
    function goRegister() {
        navigate("/register" + window.location.search);
        // window.location.href = `/register${window.location.search}`;
        var tagManagerArgs = {
            gtmId: "GTM-THGH7HH",
            dataLayer: {
                event: "einstein_telemedicina",
                event_name: "cadastro",
                step: "step2",
            },
        };
        TagManager.initialize(tagManagerArgs);
    }
    function goReset() {
        window.location.href = "/auth/reset-password/";
    }
    useEffect(function () {
        if (windowRedirect !== "" && clientId !== "") {
            localStorage.setItem("defaultRedirect", windowRedirect);
            localStorage.setItem("defaultClientId", clientId);
        }
        if (!clientId && windowRedirect === "") {
            window.history.back();
        }
    }, [clientId]);
    var onSuccessHandle = function () {
        setState(__assign(__assign({}, state), { loading: false }));
    };
    var onSuccessResencActiveAccount = function (data) {
        setState(__assign(__assign({}, state), { loading: false, showModal: false }));
        setToast({
            show: true,
            type: "success",
            message: data.message,
        });
    };
    var onErrorHandle = function (err) {
        setState(__assign(__assign({}, state), { loading: false, showModal: false }));
        setToast({
            show: true,
            type: "error",
            message: err,
        });
    };
    var initalFormState = {
        document: "",
        password: "",
    };
    var formik = useFormik({
        initialValues: initalFormState,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: validationLoginSchema,
        onSubmit: function (values) { return __awaiter(_this, void 0, void 0, function () {
            var redirect, defaultClientId, tagManagerArgs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        redirect = localStorage.getItem("defaultRedirect");
                        defaultClientId = localStorage.getItem("defaultClientId");
                        setState(__assign(__assign({}, state), { showModal: false, loading: true, document: values.document }));
                        tagManagerArgs = {
                            gtmId: "GTM-THGH7HH",
                            dataLayer: {
                                event: "einstein_telemedicina",
                                event_name: "login",
                                step: "step2",
                            },
                        };
                        TagManager.initialize(tagManagerArgs);
                        return [4 /*yield*/, handleSubmitForm(__assign(__assign({}, values), { windowRedirect: redirect, clientId: defaultClientId }))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); },
    });
    var handleSubmit = formik.handleSubmit;
    var getError = function (key) {
        return formik.errors[key] && formik.touched[key] && formik.errors[key];
    };
    var getErrorFeedback = function (key) { return ({
        show: !!getError(key),
        text: getError(key),
        variation: "error",
    }); };
    return (_jsxs(Wrapper, { children: [_jsx(Header, {}), state.showModal && state.showTypeModal === "requalification" ? (_jsx(RequalificationModal, { isOpen: state.showModal, onError: onErrorHandle, onSuccess: onSuccessHandle, clientId: clientId, document: state.document })) : null, state.showModal && state.showTypeModal === "active-account" ? (_jsx(ResendActiveAccountModal, { isOpen: state.showModal, onError: onErrorHandle, onSuccess: onSuccessResencActiveAccount, clientId: clientId, document: state.document, isCloseable: true })) : null, _jsxs(FormSection, { children: [_jsxs(UpperSection, { children: [_jsx(TitleLogin, { children: "Acesse sua conta" }), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(Input, { label: "Documento", placeholder: "CPF / Passaporte", type: "text", name: "document", width: "100%", onBlur: formik.handleBlur("document"), feedback: getErrorFeedback("document"), onChange: function (e) { return formik.setFieldValue("document", e.target.value); }, value: formik.values.document }), _jsx(Input, { label: "Senha", placeholder: "********", type: "password", name: "password", width: "100%", onBlur: formik.handleBlur("password"), onChange: function (e) { return formik.setFieldValue("password", e.target.value); }, value: formik.values.password, viewValue: true }), _jsx("div", __assign({ className: "forget-password" }, { children: _jsx("span", __assign({ tabIndex: 0, role: "button", onClick: function () {
                                                return navigate("/recover-password/document", {
                                                    state: { document: formik.values.document },
                                                });
                                            } }, { children: "Esqueci minha senha" })) })), _jsx(Button, __assign({ loading: state.loading, disabled: Boolean(!formik.values.document ||
                                            !formik.values.password ||
                                            formik.errors.document) }, { children: "Entrar" }))] }))] }), _jsxs(BottomSection, { children: [_jsx("h3", { children: "Ol\u00E1! Primeira vez aqui?" }), _jsx("p", { children: "Cadastre-se e aproveite para acessar todos nossos servi\u00E7os." }), _jsx(Button, __assign({ variation: "outline", onClick: goRegister }, { children: "Cadastre-se aqui" }))] })] }), _jsx(Banner, {})] }));
}
