import * as Yup from "yup";
import { isValidCpf } from "../cpf";
import { setHours, setMinutes, setSeconds, setMilliseconds } from "date-fns";
var resetDay = function (date) {
    return setHours(setMinutes(setSeconds(setMilliseconds(date, 999), 59), 59), 23);
};
export var passwordValidations = [
    {
        test: function (value) { return /^.{8,32}$/.test(value); },
        message: "Contém entre 8 e 32 caracteres",
    },
    {
        test: function (value) { return /^.*[a-z]+.*$/.test(value); },
        message: "Contém letra minúscula (a-z)",
    },
    {
        test: function (value) { return /^.*[A-Z]+.*$/.test(value); },
        message: "Contém letra maiúscula (A-Z)",
    },
    {
        test: function (value) { return /^.*[0-9]+.*$/.test(value); },
        message: "Contém número (0-9)",
    },
    {
        test: function (value) {
            return /[!@#$%^&*()\[\]{}\_\'\"\`\~\\\,\.\=\+\-\|\/?]/.test(value);
        },
        message: "Cont\u00E9m caractere especial (!@#$%^&*()[]{}/?)",
    },
    {
        test: function (value) {
            return /^(?!.*012|.*123|.*234|.*345|.*456|.*567|.*678|.*789|.*890).*$/i.test(value);
        },
        message: "Não contém sequência de números",
    },
    {
        test: function (value) {
            return /^(?!.*abc|.*bcd|.*cde|.*def|.*efg|.*fgh|.*pqr|.*qrs|.*rst|.*stu|.*tuv|.*uvw|.*vwx|.*wxy|.*xyz).*$/i.test(value);
        },
        message: "Não contém sequência de letras",
    },
];
Yup.addMethod(Yup.string, "cpf", function (message) {
    var errorMessage = message || "cpf inválido";
    return this.test({
        name: "name",
        message: errorMessage,
        test: function (cpf) {
            if (cpf === void 0) { cpf = ""; }
            return isValidCpf(cpf);
        },
    });
});
export var firstName = Yup.string()
    .trim()
    .required("Campo obrigatório")
    .min(3, "mínimo 3 caracteres")
    .max(32, "máximo 32 caracteres")
    .matches(/^([a-zA-ZÀ-ú\s]+)$/gi, "Digite um nome válido");
export var lastName = Yup.string()
    .trim()
    .required("Campo obrigatório")
    .min(3, "mínimo 3 caracteres")
    .max(50, "máximo 50 caracteres")
    .matches(/^([a-zA-ZÀ-ú\s]+)$/gi, "Digite um sobrenome válido");
export var fullName = Yup.string()
    .trim()
    .required("Campo obrigatório")
    .min(7, "mínimo 7 caracteres")
    .max(50, "máximo 50 caracteres")
    .matches(/^([a-zA-ZÀ-ú\s]+)$/gi, "Digite um nome válido");
export var birthDate = Yup.date()
    .nullable()
    .required("Campo obrigatório")
    .min(new Date(1900, 1, 1), "Digite uma data válida")
    .max(resetDay(new Date()), "Digite uma data válida");
export var sex = Yup.string()
    .required("Campo obrigatório")
    .matches(/(M|F)/gi, "Campo obrigatório");
export var phone = Yup.string()
    .required("Campo obrigatório")
    // .matches(/^([(]?[0-9]{2}[)]?)\s?[0-9]{4,5}\-?[0-9]{4}$/ig,'<text feedback campo telefone valido>') // mascara opcional
    .matches(/^([(]?[0-9]{2}[)]?)\s?[0-9]{4,5}\-?[0-9]{4}$/gi, "Digite um telefone válido"); // mascara obrigatorio
export var foreignPhone = Yup.string().required("Campo obrigatório").min(7);
// .matches(/^([(]?[0-9]{2}[)]?)\s?[0-9]{4,5}\-?[0-9]{4}$/ig,'<text feedback campo telefone valido>') // mascara opcional
//   .matches(
//     /^\+[0-9]{3}\s[0-9]{3}\s[0-9]{3}\s[0-9]{3}$/gi,
//     "Digite um telefone válido"
//   ); // mascara obrigatorio
export var nationality = Yup.string()
    .required("Campo obrigatório")
    .matches(/(brasileiro|estrangeiro)/gi, "Campo obrigatório");
export var foreingPassaport = Yup.string()
    .required("Campo obrigatório")
    .min(3, "Campo obrigatório")
    .matches(/^([^A-Z]*)$/, "Insira seu passporte sem letras maiusculas.")
    .matches(/^[a-zA-Z0-9.]+$/, "Insira seu passporte sem caracteres especiais.")
    .test("Insira seu passporte sem pontos.", "Insira seu passporte sem pontos.", function (value) { return !value.includes("."); });
export var loginDocument = Yup.string()
    .required("Campo obrigatório")
    .min(1)
    .matches(/^([^A-Z]*)$/, "Insira seu documento sem letras maiusculas.")
    .matches(/^[a-zA-Z0-9.]+$/, "Insira seu documento sem caracteres especiais.")
    .test("Insira seu documento sem pontos.", "Insira seu documento sem pontos.", function (value) { return !value.includes("."); });
export var cpf = Yup.string()
    .required("Campo obrigatório")
    //@ts-ignore
    .cpf("Digite um CPF válido");
export var email = Yup.string()
    .trim("Campo e-mail não pode conter espaços")
    .strict(true)
    .email("Digite um e-mail válido")
    .required("Campo obrigatório")
    .max(255, "O e-mail deve ter no máximo 255 caractéres");
export var valueForRecoverPassword = Yup.string()
    .required("Campo obrigatório")
    .min(1, "Digite um e-mail ou documento válido")
    .max(255, "O e-mail ou documento deve ter no máximo 255 caractéres");
export var password = Yup.string()
    .required("Campo obrigatório")
    .max(32)
    .test("Senha inválida", "Digite uma senha válida", function (value) {
    return passwordValidations
        .map(function (elem) { return elem.test(value); })
        .every(function (value) { return value; });
})
    .max(32, "A senha deve ter no máximo 32 caracteres")
    .min(8, "A senha deve ter no mínimo 8 caracteres");
export var confirmField = function (field, message) {
    return Yup.string()
        .trim(message)
        .strict(true)
        .required("Campo confirma\u00E7\u00E3o obrigat\u00F3rio")
        .oneOf([Yup.ref(field), null], message);
};
export var termProcess = Yup.boolean()
    .required("\u00C9 necess\u00E1rio aceitar o termo uso de dados")
    .oneOf([true], "É necessário aceitar o termo uso de dados");
export var termService = Yup.boolean()
    .required("\u00C9 necess\u00E1rio aceitar o termo de servi\u00E7o")
    .oneOf([true], "É necessário aceitar o termo de serviço");
export var message = Yup.string()
    .trim()
    .required("Campo obrigatório")
    .min(7, "mínimo 7 caracteres");
