var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ENV } from "../../config/env";
import { Header } from "../../components";
import { useApp } from "../../context/app";
import { Title } from "../../assets/styles/default";
import { useToast } from "../../hooks/toast";
import { useClientData } from "../../hooks/clientData.hook";
import { RegisterForm } from "./Partial/registerForm";
import { RegisterSuccessModal } from "./Partial/successModal";
import { Container, Main, FormContainer } from "./styles";
var Register = function () {
    var _a = useApp().state, defaultRedirect = _a.login.defaultRedirect, title = _a.register.title;
    var _b = useToast(), setToast = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), complete = _d[0], setComplete = _d[1];
    var _e = useState(false), openModal = _e[0], setOpenModal = _e[1];
    var _f = useClientData(), clientIdUrl = _f.clientId, getItem = _f.getItem;
    var clientId = clientIdUrl || getItem("clientId");
    var _g = useClientData().redirect, windowRedirect = _g === void 0 ? defaultRedirect : _g;
    function decodedUrl(windowUrl) {
        var url = !!windowUrl && window.atob(windowUrl);
        var _a = url.split("?"), urlWithouQuery = _a[0], _b = _a[1], paramsFromWindow = _b === void 0 ? "" : _b;
        return urlWithouQuery;
    }
    function handleSubmit(values) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var url, firstName, lastName, email, phone, document, nationality, birthDate, sex, password, response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        url = windowRedirect ? decodedUrl(windowRedirect) : "";
                        setLoading(true);
                        firstName = values.firstName, lastName = values.lastName, email = values.email, phone = values.phone, document = values.document, nationality = values.nationality, birthDate = values.birthDate, sex = values.sex, password = values.password;
                        return [4 /*yield*/, axios.post(ENV.REGISTER_PATIENT + "/users", {
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                phone: phone,
                                document: document,
                                documentType: nationality === "estrangeiro" ? "PASSAPORTE" : "CPF",
                                birthDate: birthDate,
                                sex: sex,
                                password: password,
                                upload: !!(url === "/app-payment"),
                            })];
                    case 1:
                        response = _c.sent();
                        setLoading(false);
                        setComplete(true);
                        if (url === "/app-payment") {
                            localStorage.setItem("api_token", response.data.auth_token);
                            localStorage.setItem("api_patientToken", response.data.patient_token);
                            localStorage.setItem("token", response.data.auth_token);
                            localStorage.setItem("patientToken", response.data.patient_token);
                            return [2 /*return*/, window.location.replace(url)];
                        }
                        else {
                            setOpenModal(true);
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _c.sent();
                        setToast({
                            message: ((_b = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) ||
                                "Não foi possivel criar sua conta agora, por favor tente mais tarde",
                            type: "error",
                            show: true,
                        });
                        setLoading(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        if (!clientId) {
            window.history.back();
        }
    }, [clientId]);
    return (_jsxs(_Fragment, { children: [_jsxs(Container, { children: [_jsx(Header, {}), _jsx(Main, { children: _jsxs(FormContainer, { children: [_jsx(Title, { children: "Cadastro" }), _jsx("p", { children: title }), _jsx(RegisterForm, { disableSubmit: complete, loading: !!loading, onSubmit: handleSubmit })] }) })] }), _jsx(RegisterSuccessModal, { isOpen: openModal })] }));
};
export default Register;
