export default {
    GRAY: "#DEDEDE",
    BACKGROUND: "#F7F8FD",
    BLACK_PRIMARY: "#333",
    BLACK_SECONDARY: "#666",
    BLACK_TERTIARY: "#999",
    BLACK_QUARTENARY: "#373F45",
    BLACK_QUINTENARY: "#333333",
    BLUE_PRIMARY: "#0274D2",
    BLUE_SECONDARY: "#0175d2",
    BLUE_TERTIARY: "#094674",
    BLUE_MEDIUM: "#0274D2",
    CYAN_PRIMARY: "#00beb1",
    GRAY_QUINTENARY: "#8E959A",
    GRAY_SEXTENARY: "#DCE1E5",
    GREEN_PRIMARY: "#16af92",
    GREEN_SECONDARY_LIGHT: "#16beb1",
    GREY_PRIMARY: "#455b6314",
    GREY_SEPTENARY: "#B0B8BE",
    GREY_QUARTENARY: "#E1E3E4",
    GREY_SECONDARY: "#373F45",
    GREY_TERTIARY: "#697177",
    GREY_OCTNARY: "#CCCCCC",
    GREY_DECENARY: "#707070",
    RED_SECONDARY: "#F53748",
    WHITE_PRIMARY: "#ffffff",
    WHITE_QUINTENARY: "#f6f7f7",
    WHITE_QUARTENARY: "#F7F8FD",
    WHITE_TERTIARY: "#F5F6FB",
    SNACK_BG_INFO: "#999",
    SNACK_BG_SUCC: "#01af93",
    SNACK_BG_DANGER: "#ce3f3f",
    SNACK_BTN_INFO: "#666",
    SNACK_BTN_SUCC: "#00a084",
    SNACK_BTN_DANGER: "#8e2b2b",
    GREEN_SECONDARY: "#15b193",
    GREY_NINARY: "#3C3C4399",
    YELLOW_PRIMARY: "#FBC02D",
    GRAY_OTHER: "#7070704D",
    GRAY_OPACITY: "#C8D1D9",
};
