export var maskMail = function (email) {
    if (!email) {
        return email;
    }
    if ((email === null || email === void 0 ? void 0 : email.length) == 0) {
        return email;
    }
    var _a = email.split("@"), mailBody = _a[0], mailComplement = _a[1];
    var mailBodyStart = mailBody.slice(0, 2);
    mailBodyStart = mailBodyStart.padEnd(7, "*");
    var mailBodyEnd = mailBody.slice(mailBody.length - 2, mailBody.length);
    var emailStart = mailBodyStart + mailBodyEnd;
    var _b = mailComplement.split("."), mailProvider = _b[0], lastDot = _b[1];
    mailProvider = mailProvider.slice(0, 1);
    var emailEnd = mailProvider.padEnd(6, "*");
    var fullMail = "".concat(emailStart, "@").concat(emailEnd);
    return fullMail;
};
export var maskPhone = function (phone) {
    if (!phone) {
        return phone;
    }
    if ((phone === null || phone === void 0 ? void 0 : phone.length) == 0) {
        return phone;
    }
    var DDD = phone.substr(0, 2);
    var lastCharacters = phone.substr(phone.length - 4);
    return "*********-".concat(lastCharacters);
};
export var maskPhoneDDD = function (phone) {
    if (!phone) {
        return phone;
    }
    if ((phone === null || phone === void 0 ? void 0 : phone.length) == 0) {
        return phone;
    }
    var DDD = phone.substr(0, 2);
    var lastCharacters = phone.substr(phone.length - 4);
    return "(**) *****-".concat(lastCharacters);
};
export var maskCare = function (identification) {
    if (!identification) {
        return identification;
    }
    if ((identification === null || identification === void 0 ? void 0 : identification.length) == 0) {
        return identification;
    }
    var lastCharacters = identification.substr(identification.length - 4);
    return "**** **** ".concat(lastCharacters);
};
