var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { Header, Input, Select, TextArea, Button } from "../../components";
import { ENV } from "../../config/env";
import { useToast } from "../../hooks/toast";
import { validationSupportBrasileiroSchema, validationSupportEstrangeiroSchema, } from "./schema";
import { BoxHelper, Card, Centralize, ColumnHelper, ColumnInput, Container, Content, Main, Padding, Paragraph, ParagrapCard, SubTitle, Title, TitleCard, Column, } from "./styles";
var Support = function () {
    var _a = useToast(), setToast = _a[1];
    var _b = useState({
        name: "",
        email: "",
        phone: "",
        nationality: "brasileiro",
        document: "",
        message: "",
        loading: false,
    }), state = _b[0], setState = _b[1];
    var initalFormState = {
        name: "",
        email: "",
        phone: "",
        document: "",
        message: "",
        nationality: "",
    };
    function setNacionality(value) {
        setState(function (prev) {
            return __assign(__assign({}, prev), { nationality: value });
        });
    }
    function handleSubmitForm(values) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var dataSupport, data, messageResponse, err_1, _b, status, error, message;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        setState(__assign(__assign({}, state), { loading: true }));
                        dataSupport = {
                            name: values.name.replace(/\s+/g, " ").trim(),
                            email: values.email,
                            phone: values.phone,
                            document: values.document,
                            text: values.message,
                        };
                        return [4 /*yield*/, axios.post("".concat(ENV.REGISTER_PATIENT, "/support/send-email"), dataSupport)];
                    case 1:
                        data = (_c.sent()).data;
                        messageResponse = (data || {}).message;
                        setToast({
                            show: true,
                            type: "info",
                            message: messageResponse || "Enviado com sucesso.",
                        });
                        setState(__assign(__assign({}, state), { loading: false }));
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _c.sent();
                        _b = ((_a = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _a === void 0 ? void 0 : _a.data) || {}, status = _b.status, error = _b.error, message = _b.message;
                        loginErrorTakeAction({ status: status, error: error, message: message });
                        setState(__assign(__assign({}, state), { loading: false }));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function loginErrorTakeAction(data) {
        var status = data.status, error = data.error, message = data.message;
        setToast({
            show: true,
            type: "error",
            message: message,
        });
    }
    var formik = useFormik({
        initialValues: initalFormState,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: state.nationality === "brasileiro"
            ? validationSupportBrasileiroSchema
            : validationSupportEstrangeiroSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleSubmitForm(values)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
    });
    var handleSubmit = formik.handleSubmit;
    var getError = function (key) {
        return formik.errors[key] && formik.touched[key] && formik.errors[key];
    };
    var getErrorFeedback = function (key) { return ({
        show: !!getError(key),
        text: getError(key),
        variation: "error",
    }); };
    var phoneNumeric = function (tel) {
        return tel
            .split("")
            .filter(function (elem) { return /\d/.test(elem); })
            .join("");
    };
    var isMobile = function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    function goWhatsapp() {
        // +55 11 3620-2550 // whats
        // +55 11 2151-7792 // clinic
        var phone = "+55 11 3620-2550";
        var text = "";
        var textParam = text ? "text=".concat(text) : "";
        var phoneParam = "phone=".concat(phoneNumeric(phone));
        var url = isMobile()
            ? "whatsapp://send?".concat(phoneParam, "&").concat(textParam)
            : "https://wa.me/".concat(phoneNumeric(phone));
        window.location.href = url;
    }
    function goPhone() {
        var url = "tel:".concat(phoneNumeric("+55 11 2151-7690"));
        window.location.href = url;
    }
    return (_jsx(_Fragment, { children: _jsxs(Container, { children: [_jsx(Header, {}), _jsx(Main, { children: _jsxs(Content, { children: [_jsxs(ColumnInput, { children: [_jsx(Title, { children: "Fale com o suporte" }), _jsx(Paragraph, { children: "Preencha seus dados abaixo para retornamos entrar em contato." }), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsxs(Column, { children: [_jsxs(Padding, { children: [_jsx(Input, { label: "Nome", placeholder: "Nome", type: "text", name: "name", width: "100%", onBlur: formik.handleBlur("name"), feedback: getErrorFeedback("name"), onChange: function (e) {
                                                                    return formik.setFieldValue("name", e.target.value);
                                                                }, value: formik.values.name }), _jsx(Input, { label: "E-mail", placeholder: "E-mail", type: "text", name: "email", width: "100%", onBlur: formik.handleBlur("email"), feedback: getErrorFeedback("email"), onChange: function (e) {
                                                                    return formik.setFieldValue("email", e.target.value);
                                                                }, value: formik.values.email }), _jsx(Select, { label: "Nacionalidade", name: "nationality", onBlur: function (e) { return setNacionality(e.target.value); }, onChange: function (e) { return setNacionality(e.target.value); }, value: state.nationality, options: [
                                                                    {
                                                                        value: "brasileiro",
                                                                        name: "Brasileiro",
                                                                        selected: true,
                                                                        disabled: false,
                                                                    },
                                                                    {
                                                                        value: "estrangeiro",
                                                                        name: "Estrangeiro",
                                                                        selected: false,
                                                                        disabled: false,
                                                                    },
                                                                ] }), _jsx(Input, { label: "DDD + telefone", placeholder: "DDD + telefone", type: "text", name: "phone", width: "100%", onBlur: formik.handleBlur("phone"), feedback: getErrorFeedback("phone"), onChange: function (e) {
                                                                    return formik.setFieldValue("phone", e.target.value);
                                                                }, value: formik.values.phone })] }), _jsxs(Padding, { children: [_jsx(Input, { label: state.nationality !== "estrangeiro"
                                                                    ? "CPF"
                                                                    : "Passaporte", placeholder: state.nationality !== "estrangeiro"
                                                                    ? "CPF"
                                                                    : "Passaporte", type: "text", name: "document", width: "100%", onBlur: formik.handleBlur("document"), feedback: getErrorFeedback("document"), onChange: function (e) {
                                                                    return formik.setFieldValue("document", e.target.value);
                                                                }, value: formik.values.document }), _jsx(TextArea, { label: "Mensagem", placeholder: "Mensagem", type: "text", name: "message", width: "100%", height: "220px", onBlur: formik.handleBlur("message"), feedback: getErrorFeedback("message"), onChange: function (e) {
                                                                    return formik.setFieldValue("message", e.target.value);
                                                                }, value: formik.values.message })] })] }), _jsx(Centralize, { children: _jsx(Button, __assign({ loading: !!state.loading, disabled: Boolean(!formik.values.email || formik.errors.email) }, { children: "Enviar" })) })] }))] }), _jsx(ColumnHelper, { children: _jsxs(BoxHelper, { children: [_jsx(SubTitle, { children: "Se preferir voc\u00EA pode falar com nossa equipe de suporte por esses outros canais:" }), _jsxs(Card, __assign({ onClick: goWhatsapp }, { children: [_jsx(TitleCard, { children: "Whatsapp" }), _jsx(ParagrapCard, { children: "Fale conosco atrav\u00E9s do nosso canal no WhatsApp." })] })), _jsxs(Card, __assign({ onClick: goPhone }, { children: [_jsx(TitleCard, { children: "Telefone" }), _jsx(ParagrapCard, { children: "Entre em contato pelo n\u00FAmero +55 (11) 2151-7690." })] }))] }) })] }) })] }) }));
};
export default Support;
