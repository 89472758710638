import { useContext } from "react";
import { ToastStateContext } from "../context/toast";
export function useToast() {
    var context = useContext(ToastStateContext);
    if (context === undefined) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    var state = context.state, dispatch = context.dispatch;
    var setToast = function (newState) {
        return dispatch({ payload: newState, type: "set-toast" });
    };
    return [state, setToast];
}
