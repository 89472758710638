var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Title } from "../../assets/styles/default";
import { Container } from "../../components";
export var TitleLogin = styled(Title)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: column;\n  height: 100%;\n  width: 100vw;\n"], ["\n  display: flex;\n  justify-content: column;\n  height: 100%;\n  width: 100vw;\n"])));
export var FormSection = styled(Container)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 45%;\n  margin-top: 100px;\n  max-width: 405px;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  /* height: 100%; */\n  @media (max-width: 880px) {\n    width: 100%;\n    height: 100vh;\n    margin-top: 0px;\n  }\n\n  @media (max-width: 768px) {\n    margin-top: 71px;\n  }\n"], ["\n  width: 45%;\n  margin-top: 100px;\n  max-width: 405px;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  /* height: 100%; */\n  @media (max-width: 880px) {\n    width: 100%;\n    height: 100vh;\n    margin-top: 0px;\n  }\n\n  @media (max-width: 768px) {\n    margin-top: 71px;\n  }\n"])));
export var UpperSection = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 40px 0px;\n  h3 {\n    margin-bottom: 20px;\n  }\n  .forget-password {\n    text-align: left;\n    font-size: 14px !important;\n    color: #004f92;\n    font-weight: 500;\n    margin-top: 10px;\n    &:hover {\n      cursor: pointer;\n      text-decoration: underline;\n    }\n  }\n  @media (max-width: 768px) {\n    margin-bottom: 60px;\n    padding-top: 40px;\n  }\n"], ["\n  padding: 40px 0px;\n  h3 {\n    margin-bottom: 20px;\n  }\n  .forget-password {\n    text-align: left;\n    font-size: 14px !important;\n    color: #004f92;\n    font-weight: 500;\n    margin-top: 10px;\n    &:hover {\n      cursor: pointer;\n      text-decoration: underline;\n    }\n  }\n  @media (max-width: 768px) {\n    margin-bottom: 60px;\n    padding-top: 40px;\n  }\n"])));
export var BottomSection = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-top: 1px solid #dce1e5;\n  width: 100%;\n  min-height: 280px;\n  padding: 40px 0px;\n\n  h3 {\n    color: #0e4f92;\n    font-weight: 500;\n  }\n  p {\n    margin-top: 20px;\n  }\n"], ["\n  border-top: 1px solid #dce1e5;\n  width: 100%;\n  min-height: 280px;\n  padding: 40px 0px;\n\n  h3 {\n    color: #0e4f92;\n    font-weight: 500;\n  }\n  p {\n    margin-top: 20px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
