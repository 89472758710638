import { getValue } from "firebase/remote-config";
import remoteConfig from "./remoteConfig.hook";
var getRemoteConfigValue = function (value) {
    var _value = getValue(remoteConfig, value)._value;
    function isArrayString(str) {
        return /^\[.*\]$/.test(str);
    }
    function isObjectString(str) {
        return /^\{.*\}$/.test(str);
    }
    function isNumericString(str) {
        return /^\d+$/.test(str);
    }
    if (isArrayString(_value)) {
        return JSON.parse(_value);
    }
    if (isObjectString(_value)) {
        return JSON.parse(_value);
    }
    if (_value === "false" || _value === "true") {
        return JSON.parse(_value);
    }
    if (isNumericString(_value)) {
        return JSON.parse(_value);
    }
    return _value;
};
export default getRemoteConfigValue;
