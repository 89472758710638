var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { StepsContext } from "../context/steps";
import Theme from "../assets/themes";
import { ThemeProvider } from "styled-components";
import { useApp } from "../context/app";
function mergeInitialProps(iniitial, params) {
    var _a, _b, _c;
    var payload = {
        login: Object.assign(iniitial.login, (_a = params === null || params === void 0 ? void 0 : params.login) !== null && _a !== void 0 ? _a : {}),
        main: Object.assign(iniitial.main, (_b = params === null || params === void 0 ? void 0 : params.main) !== null && _b !== void 0 ? _b : {}),
        register: Object.assign(iniitial.register, (_c = params === null || params === void 0 ? void 0 : params.register) !== null && _c !== void 0 ? _c : {}),
    };
    return payload;
}
export var ContainerApp = function (props) {
    var _a = useApp(), state = _a.state, dispatch = _a.dispatch;
    var _b = useState("login"), step = _b[0], setStep = _b[1];
    var payload = mergeInitialProps(state, props);
    var setConfig = function () {
        return dispatch({
            type: "set-config",
            payload: payload,
        });
    };
    useEffect(function () {
        setConfig();
        // @ts-ignore
    }, [props]);
    return (_jsx("div", { children: _jsx(ThemeProvider, __assign({ theme: Theme }, { children: _jsx(StepsContext.Provider, __assign({ value: { step: step, setStep: setStep } }, { children: props.children })) })) }));
};
