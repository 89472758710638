var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import colors from "../../../../assets/colors";
export var LabelEmail = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 18px;\n"], ["\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 18px;\n"])));
export var Title = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
export var Paragraph = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
export var OptionCardContainer = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 26px 11px 26px 16px;\n  background-color: white;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: space-between;\n  border-radius: 8px;\n  padding-right: 60px;\n  box-shadow: 0px 2px 4.2px rgba(0, 0, 0, 0.08);\n  display: flex;\n"], ["\n  padding: 26px 11px 26px 16px;\n  background-color: white;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: space-between;\n  border-radius: 8px;\n  padding-right: 60px;\n  box-shadow: 0px 2px 4.2px rgba(0, 0, 0, 0.08);\n  display: flex;\n"])));
export var OptionTitleContainer = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var OptionContentContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"])));
export var OptionIconContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n  width: 46px;\n"], ["\n  justify-content: center;\n  align-items: center;\n  width: 46px;\n"])));
export var OptionTextsContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-left: 16px;\n  flex-direction: column;\n"], ["\n  padding-left: 16px;\n  flex-direction: column;\n"])));
export var OptionTitle = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  margin-right: 100px;\n  font-size: 16px;\n  padding-bottom: 4px;\n  font-weight: bold;\n"], ["\n  color: ", ";\n  margin-right: 100px;\n  font-size: 16px;\n  padding-bottom: 4px;\n  font-weight: bold;\n"])), colors.BLACK_PRIMARY);
export var OptionSubtitle = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: #8e959a;\n  font-size: 14px;\n"], ["\n  color: #8e959a;\n  font-size: 14px;\n"])));
export var ParagraphChoice = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 13px;\n  color: ", ";\n  display: flex;\n  padding: 1px 0;\n"], ["\n  font-size: 13px;\n  color: ", ";\n  display: flex;\n  padding: 1px 0;\n"])), colors.GREY_SEPTENARY);
export var RadioColumnText = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-content: center;\n  align-items: flex-start;\n  flex-direction: column;\n"], ["\n  display: flex;\n  align-content: center;\n  align-items: flex-start;\n  flex-direction: column;\n"])));
export var RadioColumnSelect = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (prop) { return ({
    width: prop.width || 10,
    paddingLeft: 10,
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
}); });
export var RadioColumnIcon = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (prop) { return ({
    width: prop.width || 45,
    paddingLeft: 10,
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
}); });
export var RadioBorderExternal = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (prop) { return ({
    width: 20,
    height: 20,
    backgroundColor: prop.selected ? colors.BLUE_PRIMARY : colors.GREY_OCTNARY,
    borderRadius: 20,
    padding: 1,
}); });
export var RadioBorderInternalWhite = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    width: 18,
    height: 18,
    backgroundColor: "#fff",
    borderRadius: 18,
    padding: 3,
}); });
export var RadioBorderInternalCircle = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (prop) { return ({
    width: 12,
    height: 12,
    backgroundColor: prop.selected ? colors.BLUE_PRIMARY : "white",
    borderRadius: 16,
}); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
