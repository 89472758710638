var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import colors from "../../../../assets/colors";
export var Paragraph = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  padding: 7px 10px 0px 10px;\n  color: ", ";\n"], ["\n  display: flex;\n  padding: 7px 10px 0px 10px;\n  color: ", ";\n"])), colors.BLACK_TERTIARY);
export var ParagraphInsertCode = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  padding: 20px 20px;\n  line-height: 20px;\n  text-align: center;\n"], ["\n  color: ", ";\n  display: flex;\n  padding: 20px 20px;\n  line-height: 20px;\n  text-align: center;\n"])), colors.GREY_SECONDARY);
export var ParagraphSmaller = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  padding: 10px 0;\n  line-height: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  color: ", ";\n  display: flex;\n  padding: 10px 0;\n  line-height: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])), colors.GREY_DECENARY);
export var ParagraphAlert = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n"], ["\n  color: ", ";\n  display: flex;\n"])), colors.RED_SECONDARY);
export var Strong = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 700;\n"], ["\n  font-weight: 700;\n"])));
export var Timer = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  padding: 10px 0;\n  line-height: 20px;\n  text-align: center;\n  height: 45px;\n"], ["\n  color: ", ";\n  display: flex;\n  padding: 10px 0;\n  line-height: 20px;\n  text-align: center;\n  height: 45px;\n"])), colors.BLACK_PRIMARY);
export var LineBarContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (prop) { return ({
    width: prop.width,
    height: prop.height || 5,
}); });
export var LineBarBlue = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", ";\n  display: flex;\n  background-color: ", ";\n"], ["\n  ", ";\n  display: flex;\n  background-color: ", ";\n"])), function (prop) { return ({
    width: prop.width,
    height: prop.height || 5,
    borderRadius: 3,
}); }, colors.BLUE_PRIMARY);
export var LineBarGrey = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n  display: flex;\n  background-color: ", ";\n"], ["\n  ", ";\n  display: flex;\n  background-color: ", ";\n"])), function (prop) { return ({
    width: prop.width,
    height: prop.height || 5,
    alignItems: "flex-end",
    alignContent: "center",
    borderRadius: 3,
}); }, colors.GREY_QUARTENARY);
export var TextHiperlink = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  cursor: pointer;\n  line-height: 21px;\n  ", "\n"], ["\n  cursor: pointer;\n  line-height: 21px;\n  ", "\n"])), function (prop) { return ({
    color: prop.color || colors.BLUE_SECONDARY,
}); });
export var ArrowBackContainer = styled.button(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  margin-top: 35px;\n  padding: 25px;\n"], ["\n  display: flex;\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  margin-top: 35px;\n  padding: 25px;\n"])));
export var TopContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 50px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 50px;\n"])));
export var ModalTitle = styled.span.attrs({
    adjustsFontSizeToFit: true,
    maxFontSizeMultiplier: 1.2,
})(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 22px;\n  margin-bottom: 10px;\n  line-height: 26px;\n"], ["\n  color: ", ";\n  font-size: 22px;\n  margin-bottom: 10px;\n  line-height: 26px;\n"])), colors.BLACK_PRIMARY);
export var Divider = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject([""], [""])));
export var BodyText = styled.span.attrs({
    adjustsFontSizeToFit: true,
    maxFontSizeMultiplier: 1,
})(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 16px;\n  line-height: 22px;\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: 16px;\n  line-height: 22px;\n  text-align: center;\n"])), colors.GREY_SECONDARY);
export var IconCentralize = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    display: "flex",
    alignItems: "center",
}); });
export var ButtonsGroup = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
}); });
export var RadioBorderExternal = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (prop) { return ({
    width: 20,
    height: 20,
    backgroundColor: prop.selected ? colors.BLUE_PRIMARY : colors.GREY_OCTNARY,
    borderRadius: 20,
    padding: 1,
}); });
export var RadioBorderInternalWhite = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    width: 18,
    height: 18,
    backgroundColor: "#fff",
    borderRadius: 18,
    padding: 3,
}); });
export var RadioBorderInternalCircle = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (prop) { return ({
    width: 12,
    height: 12,
    backgroundColor: prop.selected ? colors.BLUE_PRIMARY : colors.GREY_OCTNARY,
    borderRadius: 16,
}); });
export var DigitsGroup = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
}); });
export var DigitsLine = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    display: "flex",
    flexDirection: "row",
}); });
export var TextInputDigit = styled.input(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    width: 45,
    marginRight: 5,
    borderColor: "red",
}); });
export var ViewResendCode = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
}); });
export var ViewResendCodeColumn = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])));
export var ViewTimer = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column",
    marginTop: 20,
}); });
export var ViewDetails = styled.div(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return ({
    paddingLeft: 25,
    paddingRight: 25,
    marginTop: 30,
}); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28;
