var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { setDefaultLocale } from "react-datepicker";
import { InputFeedback } from "../../components";
import { InputContent, InputWithMask, InputDatePicker } from "./styles";
import "react-datepicker/dist/react-datepicker.css";
setDefaultLocale("pt-BR");
export var DatePicker = function (props) {
    var placeholder = props.placeholder, label = props.label, _a = props.feedback, feedback = _a === void 0 ? {
        show: false,
        text: "",
        variation: "info",
    } : _a;
    var show = feedback.show, text = feedback.text, variation = feedback.variation;
    return (_jsxs(InputContent, __assign({ className: "is--".concat(show ? "error" : "info") }, { children: [_jsx(InputDatePicker, __assign({ placeholderText: placeholder, customInput: _jsx(InputWithMask, { mask: "99/99/9999" }) }, props)), _jsx("label", { children: label }), show ? (_jsx(InputFeedback, __assign({ variation: variation }, { children: text }))) : null] })));
};
