var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var ModalContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n"])));
export var ModalTop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n\n  .title {\n    width: 30vw;\n  }\n\n  .icon {\n    margin: 30px 0px 30px 0px;\n  }\n\n  h2 {\n    text-align: center;\n  }\n\n  p {\n    font-size: 14px;\n    text-align: center;\n  }\n\n  @media (max-width: 768px) {\n    .title {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n\n  .title {\n    width: 30vw;\n  }\n\n  .icon {\n    margin: 30px 0px 30px 0px;\n  }\n\n  h2 {\n    text-align: center;\n  }\n\n  p {\n    font-size: 14px;\n    text-align: center;\n  }\n\n  @media (max-width: 768px) {\n    .title {\n      width: 100%;\n    }\n  }\n"])));
export var ModalBottom = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-evenly;\n  flex-wrap: wrap-reverse;\n  margin-top: 30px;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-evenly;\n  flex-wrap: wrap-reverse;\n  margin-top: 30px;\n  width: 100%;\n"])));
export var ButtonCloseContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n"], ["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n"])));
export var ButtonClose = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  float: right;\n  background: transparent;\n"], ["\n  cursor: pointer;\n  float: right;\n  background: transparent;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
