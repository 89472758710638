import * as Yup from "yup";
import { email, phone, fullName, loginDocument, message, foreignPhone, cpf, } from "../../validation/validators/formSchema";
export var validationSupportBrasileiroSchema = Yup.object({
    name: fullName,
    email: email,
    phone: phone,
    document: cpf,
    message: message,
});
export var validationSupportEstrangeiroSchema = Yup.object({
    name: fullName,
    email: email,
    message: message,
    phone: foreignPhone,
    document: loginDocument,
});
