var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
var skeleton = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #eee;\n  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);\n  border-radius: 5px;\n  background-size: 200% 100%;\n  animation: 1.5s shine linear infinite;\n\n  @keyframes shine {\n    to {\n      background-position-x: -200%;\n    }\n  }\n"], ["\n  background: #eee;\n  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);\n  border-radius: 5px;\n  background-size: 200% 100%;\n  animation: 1.5s shine linear infinite;\n\n  @keyframes shine {\n    to {\n      background-position-x: -200%;\n    }\n  }\n"])));
export var MainContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  height: 100vh;\n  background: #f1f1f1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  height: 100vh;\n  background: #f1f1f1;\n"])));
export var Main = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n\n  background: #ffffff;\n  border-radius: 10px;\n  box-shadow: 0px 2px 5px #00000014;\n\n  min-width: 664px;\n  margin: auto;\n\n  @media (max-width: 768px) {\n    min-width: 327px;\n    width: 80%;\n  }\n"], ["\n  justify-content: center;\n  align-items: center;\n\n  background: #ffffff;\n  border-radius: 10px;\n  box-shadow: 0px 2px 5px #00000014;\n\n  min-width: 664px;\n  margin: auto;\n\n  @media (max-width: 768px) {\n    min-width: 327px;\n    width: 80%;\n  }\n"])));
export var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  width: 515px;\n  min-height: 512px;\n  margin: auto;\n\n  @media (max-width: 768px) {\n    width: 279px;\n    min-height: 638px;\n  }\n  @media (max-width: 640px) {\n    min-height: 600px;\n  }\n\n  @media (max-height: 430px) {\n    min-height: 330px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  width: 515px;\n  min-height: 512px;\n  margin: auto;\n\n  @media (max-width: 768px) {\n    width: 279px;\n    min-height: 638px;\n  }\n  @media (max-width: 640px) {\n    min-height: 600px;\n  }\n\n  @media (max-height: 430px) {\n    min-height: 330px;\n  }\n"])));
export var Title = styled.h4(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 40px;\n  color: #212121;\n  font-weight: 800;\n\n  ", "\n  ", "\n    \n    @media (max-width: 768px) {\n    margin-bottom: 50px;\n  }\n\n  @media (max-height: 430px) {\n    margin-bottom: 0px;\n  }\n"], ["\n  margin-bottom: 40px;\n  color: #212121;\n  font-weight: 800;\n\n  ", "\n  ", "\n    \n    @media (max-width: 768px) {\n    margin-bottom: 50px;\n  }\n\n  @media (max-height: 430px) {\n    margin-bottom: 0px;\n  }\n"])), function (props) { return (props === null || props === void 0 ? void 0 : props.loading) && skeleton; }, function (props) {
    return (props === null || props === void 0 ? void 0 : props.loading) && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      height: 20px;\n      width: 240px;\n    "], ["\n      height: 20px;\n      width: 240px;\n    "])));
});
export var SubTitle = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 10px 0 20px;\n\n  text-align: center;\n  line-height: 1.7;\n  color: #707070;\n\n  margin-top: 60px;\n  margin-bottom: 40px;\n\n  ", "\n  ", "\n\n    @media (max-width: 768px) {\n    margin-top: 80px;\n  }\n\n  @media (max-height: 430px) {\n    margin: 20px;\n  }\n"], ["\n  margin: 10px 0 20px;\n\n  text-align: center;\n  line-height: 1.7;\n  color: #707070;\n\n  margin-top: 60px;\n  margin-bottom: 40px;\n\n  ", "\n  ", "\n\n    @media (max-width: 768px) {\n    margin-top: 80px;\n  }\n\n  @media (max-height: 430px) {\n    margin: 20px;\n  }\n"])), function (props) { return (props === null || props === void 0 ? void 0 : props.loading) && skeleton; }, function (props) {
    return (props === null || props === void 0 ? void 0 : props.loading) && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      height: 55px;\n      width: inherit;\n    "], ["\n      height: 55px;\n      width: inherit;\n    "])));
});
export var DivImage = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  @media (max-height: 430px) {\n    display: none;\n  }\n"], ["\n  @media (max-height: 430px) {\n    display: none;\n  }\n"])));
export var DivButton = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: 15px;\n\n  width: 100%;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: 15px;\n\n  width: 100%;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n"])));
export var Button = styled.button(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n\n  font-weight: 600;\n  color: ", ";\n\n  cursor: pointer;\n  background: ", ";\n  border: 1px solid\n    ", ";\n  border-radius: 30px;\n\n  ", "\n\n  ", "\n\n  width: 100%;\n  height: 40px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n\n  font-weight: 600;\n  color: ", ";\n\n  cursor: pointer;\n  background: ", ";\n  border: 1px solid\n    ", ";\n  border-radius: 30px;\n\n  ", "\n\n  ", "\n\n  width: 100%;\n  height: 40px;\n"])), function (props) { return (props.textColor ? props.textColor : "white"); }, function (props) { return props.backgroundColor; }, function (props) {
    return props.borderColor ? props.borderColor : props.backgroundColor;
}, function (props) { return (props === null || props === void 0 ? void 0 : props.loading) && skeleton; }, function (props) {
    return (props === null || props === void 0 ? void 0 : props.loading) && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      border-radius: 30px;\n      border: none;\n    "], ["\n      border-radius: 30px;\n      border: none;\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
