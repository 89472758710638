var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
var contextInitialState = {
    main: {
        logo: "https://via.placeholder.com/100?text=logo",
    },
    login: {
        backgroundImage: "https://via.placeholder.com/300?text=default-background",
        defaultRedirect: "",
        clientId: "conecta-app",
    },
    register: {
        title: "<TITLE PLACEHOLDER>",
        serviceTerms: "https://telemedicina.einstein.br/termos.pdf",
        privacyPolicy: "https://www.einstein.br/sobre-einstein/politicas-site",
        contractTerms: "https://telemedicina.einstein.br/contrato.pdf",
    },
};
function appReducer(state, action) {
    switch (action.type) {
        case "set-config": {
            return __assign(__assign({}, state), action.payload);
        }
        default: {
            throw new Error("Unhandled action type: ".concat(action.type));
        }
    }
}
var AppStateContext = React.createContext(undefined);
function AppProvider(_a) {
    var children = _a.children;
    var _b = React.useReducer(appReducer, contextInitialState), state = _b[0], dispatch = _b[1];
    var value = { state: state, dispatch: dispatch };
    return (_jsx(AppStateContext.Provider, __assign({ value: value }, { children: children })));
}
function useApp() {
    var context = React.useContext(AppStateContext);
    if (context === undefined) {
        throw new Error("useApp must be used within a AppProvider");
    }
    return context;
}
export { AppProvider, useApp };
