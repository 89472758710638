var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import themes from "../../assets/themes";
import { Content as GlobalContent } from "../../assets/styles/GlobalStyles";
export var Title = styled.h2(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  text-align: left;\n  font-style: ", ";\n  line-spacing: ", ";\n  color: ", ";\n  margin-bottom: 32px;\n"], ["\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  text-align: left;\n  font-style: ", ";\n  line-spacing: ", ";\n  color: ", ";\n  margin-bottom: 32px;\n"])), themes.fonts.title.fontSize, themes.fonts.title.fontFamily, themes.fonts.title.fontWeight, themes.fonts.title.fontStyle, themes.fonts.title.lineSpacing, themes.fonts.title.color);
export var SubTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  text-align: left;\n  font-style: ", ";\n  line-spacing: ", ";\n  color: ", ";\n  margin-bottom: 32px;\n"], ["\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  text-align: left;\n  font-style: ", ";\n  line-spacing: ", ";\n  color: ", ";\n  margin-bottom: 32px;\n"])), themes.fonts.subtitle.fontSize, themes.fonts.subtitle.fontFamily, themes.fonts.subtitle.fontWeight, themes.fonts.subtitle.fontStyle, themes.fonts.subtitle.lineSpacing, themes.fonts.subtitle.color);
export var Main = styled.main(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  background-color: #fff;\n\n  @media (max-width: 900px) {\n    height: auto;\n  }\n"], ["\n  font-family: ", ";\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  background-color: #fff;\n\n  @media (max-width: 900px) {\n    height: auto;\n  }\n"])), themes.fonts.title.fontFamily);
export var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 970px;\n  font-family: ", ";\n  margin: 50px;\n  padding: 20px;\n  min-height: 600px;\n  display: flex;\n  flex-direction: row;\n  gap: 80px;\n\n  @media (max-width: 900px) {\n    flex-direction: column;\n  }\n"], ["\n  max-width: 970px;\n  font-family: ", ";\n  margin: 50px;\n  padding: 20px;\n  min-height: 600px;\n  display: flex;\n  flex-direction: row;\n  gap: 80px;\n\n  @media (max-width: 900px) {\n    flex-direction: column;\n  }\n"])), themes.fonts.title.fontFamily);
export var ColumnInput = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding-top: 20px;\n  font-size: 0.95rem;\n  flex: 1;\n"], ["\n  padding-top: 20px;\n  font-size: 0.95rem;\n  flex: 1;\n"])));
export var ColumnHelper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 40%;\n\n  @media (max-width: 900px) {\n    width: 100%;\n  }\n"], ["\n  width: 40%;\n\n  @media (max-width: 900px) {\n    width: 100%;\n  }\n"])));
export var Paragraph = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: 300;\n  color: #777;\n  line-height: 1.6;\n  margin-bottom: 30px;\n"], ["\n  font-weight: 300;\n  color: #777;\n  line-height: 1.6;\n  margin-bottom: 30px;\n"])));
export var Centralize = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var CentralizeDivider = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border-top: 1px solid #eee;\n  margin-top: 30px;\n  padding-top: 30px;\n  text-align: center;\n"], ["\n  border-top: 1px solid #eee;\n  margin-top: 30px;\n  padding-top: 30px;\n  text-align: center;\n"])));
export var Padding = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (prop) { return ({
    maxWidth: prop.maxWidth || "100%",
    paddingTop: prop.paddingTop || 0,
    paddingRight: prop.paddingRight || 0,
    paddingBottom: prop.paddingBottom || 0,
    paddingLeft: prop.paddingLeft || 0,
}); });
export var BoxHelper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background-color: #f7f8fd;\n  border-radius: 16px;\n  padding: 50px;\n  height: 100%;\n"], ["\n  background-color: #f7f8fd;\n  border-radius: 16px;\n  padding: 50px;\n  height: 100%;\n"])));
export var TitleProducthHelper = styled.a(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: #004f92 !important;\n  font-weight: 600 !important;\n  font-size: 0.85rem !important;\n  display: block;\n"], ["\n  color: #004f92 !important;\n  font-weight: 600 !important;\n  font-size: 0.85rem !important;\n  display: block;\n"])));
export var ParagraphHelper = styled.a(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: #777 !important;\n  font-size: 0.8rem !important;\n  margin: 7px 0 0 0;\n  display: block;\n"], ["\n  color: #777 !important;\n  font-size: 0.8rem !important;\n  margin: 7px 0 0 0;\n  display: block;\n"])));
export var LinkSeeMore = styled.a(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: #009dff !important;\n  font-size: 0.8rem !important;\n  margin: 7px 0 15px 0;\n  display: block;\n"], ["\n  color: #009dff !important;\n  font-size: 0.8rem !important;\n  margin: 7px 0 15px 0;\n  display: block;\n"])));
export var LinkGoBack = styled.a(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: #0007 !important;\n  font-size: 1.1rem !important;\n  display: block;\n  margin-top: -5px;\n  cursor: pointer;\n"], ["\n  color: #0007 !important;\n  font-size: 1.1rem !important;\n  display: block;\n  margin-top: -5px;\n  cursor: pointer;\n"])));
export var LogoGroup = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 35px;\n  align-items: center;\n"], ["\n  display: flex;\n  margin-bottom: 35px;\n  align-items: center;\n"])));
export var LogoSymbol = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 100px;\n"], ["\n  width: 100px;\n"])));
export var LogoText = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  flex: 1;\n  font-size: 0.9rem !important;\n"], ["\n  flex: 1;\n  font-size: 0.9rem !important;\n"])));
export var Container = styled(GlobalContent)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  background-color: #fff;\n  padding: 80px 30px 0;\n  height: 100vh;\n\n  @media (max-width: 900px) {\n    padding-bottom: 30px;\n  }\n"], ["\n  background-color: #fff;\n  padding: 80px 30px 0;\n  height: 100vh;\n\n  @media (max-width: 900px) {\n    padding-bottom: 30px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
