var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import themes from "../../assets/themes";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  font-size: 14px;\n  margin-top: 29px;\n  color: ", ";\n  span {\n    margin-left: 10px;\n  }\n  a:link {\n    color: #0096ff;\n  }\n  a:visited {\n    color: #0047ab;\n  }\n"], ["\n  display: flex;\n  align-items: flex-start;\n  font-size: 14px;\n  margin-top: 29px;\n  color: ", ";\n  span {\n    margin-left: 10px;\n  }\n  a:link {\n    color: #0096ff;\n  }\n  a:visited {\n    color: #0047ab;\n  }\n"])), themes.colors.grey);
export var ContainerCheckbox = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  position: relative;\n  cursor: pointer;\n  font-size: 20px;\n  user-select: none;\n  input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n    height: 0;\n    width: 0;\n  }\n\n  input:checked ~ .checkmark {\n    background-color: ", ";\n  }\n\n  input:checked ~ .checkmark:after {\n    display: block;\n  }\n"], ["\n  display: block;\n  position: relative;\n  cursor: pointer;\n  font-size: 20px;\n  user-select: none;\n  input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n    height: 0;\n    width: 0;\n  }\n\n  input:checked ~ .checkmark {\n    background-color: ", ";\n  }\n\n  input:checked ~ .checkmark:after {\n    display: block;\n  }\n"])), themes.colors.defaultBlue);
export var Checkmark = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  top: 0;\n  left: 0;\n  height: 1.1em;\n  width: 1.1em;\n  border: 0.8px solid ", ";\n  background: #fff;\n  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 1px 1px rgba(0, 5);\n  :after {\n    content: \"\";\n    position: absolute;\n    display: none;\n    left: 0.37em;\n    top: 0.14em;\n    width: 0.22em;\n    height: 0.5em;\n    border: solid ", ";\n    border-width: 0 0.15em 0.15em 0;\n    transform: rotate(45deg);\n  }\n"], ["\n  position: relative;\n  top: 0;\n  left: 0;\n  height: 1.1em;\n  width: 1.1em;\n  border: 0.8px solid ", ";\n  background: #fff;\n  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 1px 1px rgba(0, 5);\n  :after {\n    content: \"\";\n    position: absolute;\n    display: none;\n    left: 0.37em;\n    top: 0.14em;\n    width: 0.22em;\n    height: 0.5em;\n    border: solid ", ";\n    border-width: 0 0.15em 0.15em 0;\n    transform: rotate(45deg);\n  }\n"])), themes.colors.defaultGrey, themes.colors.white);
var templateObject_1, templateObject_2, templateObject_3;
