var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { patient_api } from "../../../infra/http/axios-http-client";
var prefix = "mfa";
export var MFAAuth = createAction("".concat(prefix, "/MFAAuth"));
export var MFAToast = createAction("".concat(prefix, "/MFAToast"));
export var setMFAWait = createAction("".concat(prefix, "/setMFAWait"));
export var setMFAStatus = createAction("".concat(prefix, "/setMFAStatus"));
export var setMFAPage = createAction("".concat(prefix, "/setMFAPage"));
export var setMFATime = createAction("".concat(prefix, "/setMFATime"));
export var setMFADigitsLength = createAction("".concat(prefix, "/setMFADigitsLength"));
export var setMFAShowOptions = createAction("".concat(prefix, "/setMFAShowOptions"));
export var setMFAOpenModal = createAction("".concat(prefix, "/setMFAOpenModal"));
export var setMFAGenerateReason = createAction("".concat(prefix, "/setMFAGenerateReason"));
export var setMFAGenerateSelected = createAction("".concat(prefix, "/setMFAGenerateSelected"));
export var setMFAGenerateRequest = createAction("".concat(prefix, "/setMFAGenerateRequest"));
export var setMFAGenerateResponse = createAction("".concat(prefix, "/setMFAGenerateResponse"));
export var setMFAVerificateCode = createAction("".concat(prefix, "/setMFAVerificateCode"));
export var setMFAVerificateKey = createAction("".concat(prefix, "/setMFAVerificateKey"));
export var setMFAVerificateResponse = createAction("".concat(prefix, "/setMFAVerificateResponse"));
export var postMFAGenerate = createAsyncThunk("".concat(prefix, "/postMFAGenerate"), function (props, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var reason, selected, body, response, data, error_1, defaultError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    reason = props.reason, selected = props.selected;
                    if (!reason && !selected) {
                        new Error("Missing reason and communication way to MFA code");
                    }
                    body = {
                        reason: reason,
                        selected: selected,
                    };
                    return [4 /*yield*/, patient_api.post("/mfa/send-code", body)];
                case 1:
                    response = _b.sent();
                    data = response.data;
                    return [2 /*return*/, data];
                case 2:
                    error_1 = _b.sent();
                    defaultError = {
                        error: "UNKNOWN_ERROR",
                        message: "unknown",
                        statusCode: 500,
                    };
                    return [2 /*return*/, rejectWithValue(error_1.response.data || defaultError)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var postMFAVerificate = createAsyncThunk("".concat(prefix, "/postMFAVerificate"), function (props, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var key, code, body, response, data, error_2, defaultError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    key = props.key, code = props.code;
                    if (!key && !code) {
                        new Error("Missing MFA key and code");
                    }
                    body = {
                        key: key,
                        code: code,
                    };
                    return [4 /*yield*/, patient_api.post("/mfa/validate-code", body)];
                case 1:
                    response = _b.sent();
                    data = response.data;
                    return [2 /*return*/, data];
                case 2:
                    error_2 = _b.sent();
                    defaultError = {
                        error: "UNKNOWN_ERROR",
                        message: "unknown",
                        statusCode: 500,
                    };
                    return [2 /*return*/, rejectWithValue(error_2.response.data || defaultError)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
