var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Router } from "./routes";
import { Toast } from "./components";
import { useToast } from "./hooks/toast";
import GlobalStyles from "./assets/styles/GlobalStyles";
import { ContainerApp } from "./Containers/app";
import store, { persistor } from "./redux/root/store";
import { PersistGate } from "redux-persist/integration/react";
import { LoginSSO } from './components/LoginSSO';
import { getRemoteConfigValue } from './hooks/RemoteConfig';
export var Steps;
(function (Steps) {
    Steps["LOGIN"] = "login";
    Steps["REGISTER"] = "register";
    Steps["FORGET_PASSWORD"] = "forget_password";
})(Steps || (Steps = {}));
export default function App(props) {
    var _a = useToast(), toast = _a[0], setToast = _a[1];
    var _b = useState(false), ssoToggle = _b[0], setSsoToggle = _b[1];
    var _c = useState(true), firebaseLoading = _c[0], setFirebaseLoading = _c[1];
    useEffect(function () {
        props.firebase.then(function () {
            setFirebaseLoading(false);
            setSsoToggle(getRemoteConfigValue('myaccount_login_enabled'));
        });
    }, [props.firebase]);
    var setConfigToast = function () {
        if (!!toast.show) {
            setTimeout(function () {
                setToast({
                    show: false,
                });
            }, toast.delay);
        }
    };
    useEffect(function () {
        setConfigToast();
        // @ts-ignore
    }, [toast.show]);
    if (firebaseLoading) {
        return _jsx(_Fragment, {});
    }
    return ssoToggle ? _jsx(LoginSSO, __assign({}, props)) : (_jsx(Provider, __assign({ store: store }, { children: _jsx(PersistGate, __assign({ loading: null, persistor: persistor }, { children: _jsxs(ContainerApp, __assign({}, props, { children: [_jsx(GlobalStyles, {}), !!toast.show ? (_jsx(Toast, { message: toast.message, variation: toast.type })) : null, _jsx(Router, {})] })) })) })));
}
