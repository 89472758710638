import axios from "axios";
var MC_API_URL = process.env.REACT_BASE_AUTH;
var PATIENT_API_URL = process.env.REACT_REGISTER_PATIENT;
export var mc_api = axios.create({
    baseURL: MC_API_URL,
    headers: {
        Accept: "application/json, text/plain, /",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json;charset=utf-8",
    },
});
export var patient_api = axios.create({
    baseURL: PATIENT_API_URL,
    headers: {
        Accept: "application/json, text/plain, /",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json;charset=utf-8",
    },
});
// api.interceptors.request.use((config: any) => {
//   try {
//     const token = window.localStorage.getItem("token");
//     if (token) config.headers["authorization"] = `Bearer ${token}`;
//   } catch (err) {
//     console.log(err);
//   } finally {
//     return config;
//   }
// });
