import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useApp } from "../../context/app";
import { HeaderSite, HeaderContainer, GradientLine, ImgLogo } from "./styles";
export var Header = function () {
    var state = useApp().state;
    function goHome() {
        window.location.href = "/login".concat(window.location.search);
    }
    var logo = state.main.logo;
    return (_jsxs(HeaderSite, { children: [_jsx(HeaderContainer, { children: _jsx(ImgLogo, { className: "logo-header", alt: "einstein logo", src: logo, onClick: goHome }) }), _jsx(GradientLine, {})] }));
};
