var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { persistReducer } from "redux-persist";
import { createReducer } from "@reduxjs/toolkit";
import { MFA_STATUS, MFA_PAGE } from "../constants";
import { MFAAuth, setMFAGenerateReason, setMFAGenerateResponse, setMFAGenerateSelected, setMFAOpenModal, setMFAPage, setMFAShowOptions, setMFAStatus, setMFAVerificateKey, setMFAVerificateResponse, setMFAWait, } from "./actions";
import storage from "redux-persist/lib/storage";
var persistConfig = {
    key: "MFA",
    version: 1,
    storage: storage,
    whitelist: [],
};
var initialStateMFA = {
    wait: false,
    status: MFA_STATUS.INITIAL,
    page: MFA_PAGE.SELECT_COMMUNICATION_WAY,
    urlVerificationCode: "",
    openModal: false,
    backToRouteName: "",
    showOptions: {
        email: true,
        sms: true,
    },
    payload: {},
    generate: {
        request: {},
        response: {},
    },
    verificate: {
        request: {},
        response: {},
    },
};
export var mfaReducer = createReducer(initialStateMFA, function (builder) {
    builder.addCase(MFAAuth, function (state, action) { return (__assign(__assign({}, state), action.payload)); });
    builder.addCase(setMFAWait, function (state, action) { return (__assign(__assign({}, state), { wait: action.payload })); });
    builder.addCase(setMFAStatus, function (state, action) { return (__assign(__assign({}, state), { status: action.payload })); });
    builder.addCase(setMFAOpenModal, function (state, action) { return (__assign(__assign({}, state), { openModal: action.payload })); });
    builder.addCase(setMFAPage, function (state, action) { return (__assign(__assign({}, state), { page: action.payload })); });
    builder.addCase(setMFAGenerateSelected, function (state, action) { return (__assign(__assign({}, state), { generate: __assign(__assign({}, state.generate), { request: __assign(__assign({}, state.generate.request), { selected: action.payload }) }) })); });
    builder.addCase(setMFAGenerateReason, function (state, action) { return (__assign(__assign({}, state), { generate: __assign(__assign({}, state.generate), { request: __assign(__assign({}, state.generate.request), { reason: action.payload }) }) })); });
    builder.addCase(setMFAGenerateResponse, function (state, action) { return (__assign(__assign({}, state), { generate: __assign(__assign({}, state.generate), { response: action.payload }) })); });
    builder.addCase(setMFAVerificateKey, function (state, action) { return (__assign(__assign({}, state), { generate: __assign(__assign({}, state.generate), { response: {
                key: action.payload,
            } }), verificate: __assign(__assign({}, state.verificate), { request: {
                key: action.payload,
            } }) })); });
    builder.addCase(setMFAVerificateResponse, function (state, action) { return (__assign(__assign({}, state), { verificate: __assign(__assign({}, state.verificate), { response: action.payload }) })); });
    builder.addCase(setMFAShowOptions, function (state, action) {
        var _a, _b;
        return (__assign(__assign({}, state), { showOptions: {
                email: (_a = action.payload) === null || _a === void 0 ? void 0 : _a.email,
                sms: (_b = action.payload) === null || _b === void 0 ? void 0 : _b.sms,
            } }));
    });
});
export default persistReducer(persistConfig, mfaReducer);
