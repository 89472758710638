var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import createSagaMiddleware from "redux-saga";
import { useDispatch as dispatch } from "react-redux";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE, } from "redux-persist";
import { configureStore, getDefaultMiddleware, } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
var sagaMiddleware = createSagaMiddleware();
var middleware = __spreadArray(__spreadArray([], getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
}), true), [
    sagaMiddleware,
], false);
var store = configureStore({
    reducer: rootReducer,
    middleware: middleware,
});
var startRootSaga = function () {
    sagaMiddleware
        .run(rootSaga)
        .toPromise()
        .catch(function (e) {
        startRootSaga();
    });
};
startRootSaga();
export var useDispatch = dispatch;
export var persistor = persistStore(store);
export default store;
