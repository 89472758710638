var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ValidPasswordIconSVG from "../../assets/icons/valid-password.svg";
import InvalidPasswordIconSVG from "../../assets/icons/invalid-password.svg";
import { passwordValidations } from "../../validation/validators/formSchema";
import { Check, PasswordCheckContent } from "./styles";
export var PasswordCheck = function (_a) {
    var password = _a.password, confirmPassword = _a.confirmPassword, firstName = _a.firstName, lastName = _a.lastName;
    function checkUpperCase() {
        return passwordValidations[2].test(password);
    }
    function checkLowerCase() {
        return passwordValidations[1].test(password);
    }
    function checkNumberCase() {
        return passwordValidations[3].test(password);
    }
    function checkSpecialSymbol() {
        return passwordValidations[4].test(password);
    }
    function checkValidLength() {
        return passwordValidations[0].test(password);
    }
    function checkSequentialNumbers() {
        return passwordValidations[5].test(password);
    }
    function checkSequentialCharacters() {
        return passwordValidations[6].test(password);
    }
    function checkHaveName() {
        return !(password.toLowerCase().includes(firstName.toLowerCase()) ||
            password.toLowerCase().includes(lastName.toLowerCase()));
    }
    function checkMatch() {
        if (!password) {
            return false;
        }
        return password === confirmPassword;
    }
    var ValidPasswordIcon = function () { return (_jsx("img", { src: ValidPasswordIconSVG, width: "9.036", height: "9.036", alt: "valid" })); };
    var InvalidPasswordIcon = function () { return (_jsx("img", { src: InvalidPasswordIconSVG, width: "9.036", height: "9.036", alt: "valid" })); };
    return (_jsxs(PasswordCheckContent, __assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsxs(Check, { children: [" ", checkValidLength() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", passwordValidations[0].message] }), _jsxs(Check, { children: [" ", checkUpperCase() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", passwordValidations[2].message] }), _jsxs(Check, { children: [" ", checkLowerCase() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", passwordValidations[1].message] }), _jsxs(Check, { children: [" ", checkNumberCase() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", passwordValidations[3].message] }), _jsxs(Check, { children: [" ", checkSpecialSymbol() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", passwordValidations[4].message] }), _jsxs(Check, { children: [" ", checkSequentialNumbers() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", passwordValidations[5].message] }), _jsxs(Check, { children: [" ", checkSequentialCharacters() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", passwordValidations[6].message] }), firstName || lastName ? (_jsxs(Check, { children: [" ", checkHaveName() ? (_jsx(ValidPasswordIcon, {})) : (_jsx(InvalidPasswordIcon, {})), " ", "Não contém seu nome"] })) : null, _jsxs(Check, { children: [" ", checkMatch() ? _jsx(ValidPasswordIcon, {}) : _jsx(InvalidPasswordIcon, {}), " As senhas devem corresponder"] })] })));
};
