import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ForgetPasswordDocument from "./modules/RecoverPassword/pages/Recover";
import Register from "./modules/Register";
import ResetPasswordMigrated from "./modules/Migrated";
import RecoverPasswordOptions from "./modules/RecoverPassword/pages/Options";
import RecoverPasswordValidation from "./modules/RecoverPassword/pages/Validation";
import Support from "./modules/Support";
import ResetPassword from "./modules/RecoverPassword/pages/Reset";
import WarningScreen from "./modules/WarningScreen";
import Login from "./modules/Login";
import { Routes, Route, BrowserRouter } from "react-router-dom";
export var Router = function (props) {
    return (_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/register", element: _jsx(Register, {}) }), _jsx(Route, { path: "/recover-password/reset", element: _jsx(ResetPasswordMigrated, {}) }), _jsx(Route, { path: "/recover-password/document", element: _jsx(ForgetPasswordDocument, {}) }), _jsx(Route, { path: "/support", element: _jsx(Support, {}) }), _jsx(Route, { path: "/recover-password/options", element: _jsx(RecoverPasswordOptions, {}) }), _jsx(Route, { path: "/recover-password/validation", element: _jsx(RecoverPasswordValidation, {}) }), _jsx(Route, { path: "/recover-password/new-password", element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: "/warning-screen", element: _jsx(WarningScreen, {}) })] }) }));
};
