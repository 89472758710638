var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { MainContainer, Main, Content, Title, SubTitle, DivImage, DivButton, Button, } from "./styles";
import ErrorPageSvg from "../../assets/images/404-error-page.svg";
import PhoneSvg from "../../assets/icons/phone.svg";
import WhatsAppSvg from "../../assets/icons/whatsapp.svg";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";
import { formatTitle, formatDescription, formatPhone } from "./helper";
var WarningScreen = function () {
    var catchSystemError = getRemoteConfigValue("catch_system_error");
    var _a = useState(""), phone = _a[0], setPhone = _a[1];
    var _b = useState(""), linkChatWhatsapp = _b[0], setLinkChatWhatsapp = _b[1];
    var _c = useState(""), title = _c[0], setTitle = _c[1];
    var _d = useState(""), description = _d[0], setDescription = _d[1];
    React.useEffect(function () {
        var timeout = setTimeout(function () {
            var _a, _b, _c, _d, _e, _f, _g;
            setTitle((_a = catchSystemError === null || catchSystemError === void 0 ? void 0 : catchSystemError.login) === null || _a === void 0 ? void 0 : _a.title);
            setDescription((_b = catchSystemError === null || catchSystemError === void 0 ? void 0 : catchSystemError.login) === null || _b === void 0 ? void 0 : _b.description);
            setPhone((_d = (_c = catchSystemError === null || catchSystemError === void 0 ? void 0 : catchSystemError.login) === null || _c === void 0 ? void 0 : _c.contact) === null || _d === void 0 ? void 0 : _d.phone);
            setLinkChatWhatsapp((_f = (_e = catchSystemError === null || catchSystemError === void 0 ? void 0 : catchSystemError.login) === null || _e === void 0 ? void 0 : _e.contact) === null || _f === void 0 ? void 0 : _f.link_chat_whatsapp);
            if (((_g = catchSystemError === null || catchSystemError === void 0 ? void 0 : catchSystemError.login) === null || _g === void 0 ? void 0 : _g.enabled) === false) {
                window.location.href = "/login";
            }
        }, 1000);
        (function () { return clearTimeout(timeout); });
    }, [catchSystemError]);
    var handlePhoneButton = function (number) {
        var cleanNumber = number.replace(/[^\d]/g, "");
        var formatNumber = cleanNumber.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
        window.location.href = "tel:+55-".concat(formatNumber); // tel:+55-12-1234-1234
    };
    var handleWhatsAppButton = function (linkChatWhatsapp) {
        window.open(linkChatWhatsapp, "_blank");
    };
    return (_jsx(MainContainer, { children: _jsx(Main, { children: _jsxs(Content, { children: [_jsx(Title, __assign({ loading: !title }, { children: formatTitle(title) })), _jsx(DivImage, { children: _jsx("img", { src: ErrorPageSvg, alt: "view-off" }) }), _jsx(SubTitle, { loading: !description, dangerouslySetInnerHTML: { __html: formatDescription(description) } }), _jsxs(DivButton, { children: [_jsx(Button, __assign({ id: "btn-warning-screen-phone", loading: !phone, borderColor: "#0E4F92", textColor: "#0E4F92", backgroundColor: "#FFF", onClick: function () { return handlePhoneButton(phone); } }, { children: phone && (_jsxs(_Fragment, { children: [_jsx("img", { src: PhoneSvg, alt: "view-off" }), formatPhone(phone)] })) })), _jsx(Button, __assign({ id: "btn-warning-screen-whatsapp", loading: !linkChatWhatsapp, backgroundColor: "#0E4F92", onClick: function () { return handleWhatsAppButton(linkChatWhatsapp); } }, { children: linkChatWhatsapp && (_jsxs(_Fragment, { children: [_jsx("img", { src: WhatsAppSvg, alt: "view-off" }), "WhatsApp"] })) }))] })] }) }) }));
};
export default WarningScreen;
