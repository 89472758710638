export function getRedirectUrl(tokens, serverUrl, windowUrl) {
    var _a = getRedirectParams(tokens, serverUrl, windowUrl), query = _a.query, urlWithouQuery = _a.urlWithouQuery, mapQuery = _a.mapQuery;
    var url = "".concat(urlWithouQuery, "?").concat(query);
    return { url: url, mapQuery: mapQuery };
}
export function getRedirectParams(tokens, serverUrl, windowUrl) {
    var url = !!windowUrl ? window.atob(windowUrl) : serverUrl;
    var _a = url.split("?"), urlWithouQuery = _a[0], _b = _a[1], paramsFromWindow = _b === void 0 ? "" : _b;
    var baseParams = paramsFromWindow.split("&").filter(String);
    var tokenParam = Object.entries(tokens).reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        return acc.concat("".concat(key, "=").concat(value));
    }, []);
    var finalParams = baseParams.concat(tokenParam).join("&");
    return {
        mapQuery: finalParams.split("&").map(function (value) { return value.split("="); }),
        query: finalParams,
        urlWithouQuery: urlWithouQuery,
    };
}
